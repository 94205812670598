import { ScuModelEnum } from "shared/types/bryx911/Scu";
import { range } from "shared/utils/functions";

export const SCU_SERIAL_REGEX = /([0-9A-F]{2})-([0-9A-F]{2})-([0-9A-F]{2})/;
export const SCU_MAC_REGEX = /[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}/;
export const SCU_MIGRATION_SERIAL = "00-00-00";

export const scuModelNameString = (model: ScuModelEnum): string => {
    switch (model) {
        case "p1":
            return "Raspberry Pi Pro";
        case "s1":
            return "Raspberry Pi Slim";
        case "s2":
            return "x86 Slim";
        case "p2":
            return "x86 Pro SuperLogics";
        case "p3":
            return "x86 Pro Logic Supply";
        case "p3g":
            return "x86 Pro Logic Supply +4G";
        case "other":
            return "Other/Unknown?";
    }
};

export const scuModelIdString = (model: ScuModelEnum): string => model.toUpperCase();

export const scuSerialToIpAddress = (serial: string): string | null => {
    const match = serial.match(SCU_SERIAL_REGEX);
    if (match == null) {
        return null;
    }
    return (
        "10.31." +
        range(2, 4)
            .map((i) => parseInt(match[i], 16))
            .join(".")
    );
};

export const scuSerialToEmail = (serial: string): string | null => {
    const match = serial.match(SCU_SERIAL_REGEX);
    if (match == null) {
        return null;
    }
    return `${serial.toLowerCase()}@scu.bryx.com`;
};

export const getScuApiJsonString = (email: string, password: string, nonSupportApiRoot: string): string =>
    JSON.stringify({
        user: email,
        password: password,
        url: nonSupportApiRoot,
    });

export const generatePassword = (length: number): string => {
    const characterSet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return range(length)
        .map(() => characterSet.charAt(Math.random() * characterSet.length))
        .join("");
};

export const timeToScuTime = (x: number) => {
    const date = new Date();
    date.setHours(Math.floor(x / 60));
    date.setMinutes(x % 60);
    date.setSeconds(0);
    return date;
};
