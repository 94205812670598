import { httpDelete, httpGet, httpPost, httpPut } from "shared/api/httpClient";
import {
    AgencyAction,
    AgencyActionParameters,
    AgencyClientsWithCount,
    LegacyAgencyTranslations,
    LegacyAgencyTranslationType,
    LongAgency,
    ShortAgency,
} from "shared/types/bryx911/Agencies";
import { AgencyClientTypeEnum } from "shared/types/bryx911/Clients";
import { ScuSearchResult } from "shared/types/bryx911/Scu";
import { InnerTranslationParameters, TranslationReturn } from "shared/types/bryx911/Translations";

export const getAgencies = (query: string) => {
    return httpGet<ShortAgency[]>(`/agencies?query=${query}`);
};

export const getAgency = (id: string) => {
    return httpGet<LongAgency>(`/agencies/${id}`);
};

export const getAgencyScus = (id: string) => {
    return httpGet<ScuSearchResult[]>(`/agencies/${id}/scus`);
};

export const pushScuConfigs = (id: string) => {
    return httpPost(`/agencies/${id}/scus/push-config`, null);
};

export const getAgencyClients = (
    id: string,
    limit: number,
    page: number,
    query: string | null,
    manager: boolean | null,
    types: AgencyClientTypeEnum[] | null,
) => {
    const body: { [index: string]: unknown } = {
        limit: limit,
        page: page,
    };

    if (query != null) {
        body.search = query;
    }

    if (manager != null) {
        body.manager = manager;
    }

    if (types != null) {
        body.types = types.join("+");
    }

    return httpGet<AgencyClientsWithCount>(`/agencies/${id}/clients`, { body: JSON.stringify(body) });
};

export const getAgencyActions = (agencyId: string) => {
    return httpGet<AgencyAction[]>(`/agencies/${agencyId}/agency-actions`);
};

export const createAgencyAction = (agencyId: string, params: AgencyActionParameters) => {
    return httpPost<AgencyActionParameters, AgencyAction>(`/agencies/${agencyId}/agency-actions`, params);
};

export const editAgencyAction = (agencyId: string, actionId: string, params: AgencyActionParameters) => {
    return httpPut<AgencyActionParameters, AgencyAction>(`/agencies/${agencyId}/agency-actions/${actionId}`, params);
};

export const deleteAgencyAction = (agencyId: string, id: string) => {
    return httpDelete(`/agencies/${agencyId}/agency-actions/${id}`);
};

export const getAgencyLegacyTranslations = (agencyId: string) => {
    return httpGet<LegacyAgencyTranslations>(`/agencies/${agencyId}/translations`);
};

export const getAgencyTranslations = (agencyId: string) => {
    return httpGet<TranslationReturn[]>(`/agencies/${agencyId}/scu-translations`);
};

export const getTranslations = (query: string) => {
    return httpGet<TranslationReturn[]>(`/scu-translations?query=${query}`);
};

export const createAgencyTranslation = (agencyId: string, type: LegacyAgencyTranslationType, pattern: string, replaceWith: string) => {
    return httpPost(`/agencies/${agencyId}/translations/${type}`, { pattern, replaceWith });
};

export const editAgencyTranslation = (
    agencyId: string,
    type: LegacyAgencyTranslationType,
    translationId: string,
    pattern: string | null,
    replaceWith: string | null,
) => {
    return httpPut(`/agencies/${agencyId}/translations/${type}/${translationId}`, { pattern, replaceWith });
};

export const deleteAgencyTranslation = (agencyId: string, type: LegacyAgencyTranslationType, translationId: string) => {
    return httpDelete(`/agencies/${agencyId}/translations/${type}/${translationId}`);
};

export const performAgencyLegacyTranslationMigration = (agencyId: string, force: boolean = false) => {
    return httpPut(`/agencies/${agencyId}/scu-translations-migration?force=${force}`, null);
};

export const deleteAgencyTranslationStage = (scuTranslationId: string, stage: string, translationId: string) => {
    return httpDelete(`/scu-translations/${scuTranslationId}/stages/${stage}/translations/${translationId}`);
};

export const editAgencyTranslationInStage = (
    scuTranslationId: string,
    stage: string,
    translationId: string,
    translation: InnerTranslationParameters,
) => {
    return httpPut(`/scu-translations/${scuTranslationId}/stages/${stage}/translations/${translationId}`, translation);
};

export const addStageToAgencyTranslation = (scuTranslationId: string, stage: string, translation: InnerTranslationParameters) => {
    return httpPost(`/scu-translations/${scuTranslationId}/stages/${stage}/translations`, translation);
};

export const editPipeline = (scuTranslationId: string, pipeline: unknown) => {
    return httpPut(`/scu-translations/${scuTranslationId}/pipeline`, pipeline);
};
