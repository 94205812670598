import { Card, Center, Flex, Grid, Title } from "@mantine/core";

export const CountWidget = ({ title, count, icon }: { title: string; count: number; icon: React.ReactNode }) => (
    <Grid.Col span={4}>
        <Card padding="lg" radius="sm" withBorder>
            <Flex m={"auto"} direction={"column"} justify={"center"} gap={16}>
                <Flex gap={8} justify={"center"}>
                    <Center>
                        {icon}
                        <Title order={1} ml="lg">
                            {count}
                        </Title>
                    </Center>
                </Flex>
                <Title order={2}>{title}</Title>
            </Flex>
        </Card>
    </Grid.Col>
);
