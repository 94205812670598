import { Toaster } from "sonner";

export const ToastContainer = ({ ...rest }) => {
    return (
        <Toaster
            {...rest}
            position="bottom-right"
            style={{ display: "flex", justifyContent: "flex-end" }}
            toastOptions={{
                style: {
                    background: "#fff",
                    boxShadow: "none",
                    padding: 0,
                    zIndex: 9e10,
                },
            }}
        />
    );
};
