import { DeepKeyOf, DeepPick } from "@tsly/deep";
import { useUserStore } from "shared/stores/userStore";
import { HttpError } from "shared/types/Http";
import { Paginated } from "shared/types/Paginated";
import { QueryBody } from "shared/types/Query";
import { buildQuery } from "shared/utils/query";

const http = async <T>(path: string, { headers, ...restConfig }: RequestInit): Promise<T> => {
    const apiKey = useUserStore.getState().session.apiKey;
    const requestUrl = `${import.meta.env.VITE_API_BASE_URL}${path}`;
    const request = new Request(requestUrl, {
        ...restConfig,
        headers: {
            ...headers,
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
            "X-BRYX-TYPE": `${import.meta.env.VITE_SITE}`,
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new HttpError(response);
    }

    return response.json().catch(() => ({})) as Promise<T>;
};

export const httpGet = async <T>(path: string, config?: RequestInit): Promise<T> => {
    const init = { method: "GET", ...config };

    return await http<T>(path, init);
};

export const httpPatch = async <T, U>(path: string, body: T, config?: RequestInit): Promise<U> => {
    const init = { method: "PATCH", body: JSON.stringify(body), ...config };

    return await http<U>(path, init);
};

export const httpPut = async <T, U>(path: string, body: T, config?: RequestInit): Promise<U> => {
    const init = { method: "PUT", body: JSON.stringify(body), ...config };

    return await http<U>(path, init);
};

export const httpPost = async <T, U>(path: string, body: T, config?: RequestInit): Promise<U> => {
    const init = { method: "POST", body: JSON.stringify(body), ...config };

    return await http<U>(path, init);
};

export const httpDelete = async <T>(path: string, config?: RequestInit): Promise<T> => {
    const init = { method: "DELETE", ...config };

    return await http<T>(path, init);
};

export const httpQuery = async <U>(path: string, body: QueryBody, config?: RequestInit): Promise<U> => {
    const init = { method: "QUERY", body: JSON.stringify(body), ...config };

    return await http<U>(path, init);
};

export function queryWithoutPagination<TBase extends object>(queryFn: (body: QueryBody) => Promise<Paginated<TBase>>) {
    return function <TCol extends DeepKeyOf<TBase>>(...cols: TCol[]) {
        const step = 10; // 10 records per pass. TODO: RMS-1228?

        const _next = async (page = 0): Promise<TBase[]> => {
            const { data } = await queryFn(
                buildQuery<TBase>((q) =>
                    q
                        .withColumn(...cols)
                        .withLimit(step)
                        .withPage(page),
                ),
            );

            if (data.length < step) return data;
            else return data.concat(await _next(page + 1));
        };

        return _next() as Promise<DeepPick<TBase, TCol>[]>;
    };
}
