import { httpDelete, httpGet, httpPost, httpPut } from "shared/api/httpClient";
import { EditableField, getEditableParam } from "shared/types/ApiTypes";
import {
    AlertCheckIssue,
    ClientEmailLogItem,
    ClientLocation,
    ClientStatusEnum,
    LongClient,
    MinimalClient,
    PreAlertCheck,
    ShortClient,
} from "shared/types/bryx911/Clients";
import { Grave } from "shared/types/bryx911/Grave";

export const getClients = (query: string) => {
    // ShortClient[]
    return httpGet<ShortClient[]>(`/clients?query=${query}`);
};

// LongClient
export const getClient = (id: string) => {
    return httpGet<LongClient>(`/clients/${id}`);
};

// MinimalClient
export const createUser = (
    commonName: string,
    givenName: string,
    surname: string,
    initials: string,
    email: string,
    password: string | null,
    phone: string | null,
    timezone: string,
) => {
    const body = {
        type: "user",
        commonName: commonName,
        givenName: givenName,
        surname: surname,
        initials: initials,
        email: email,
        password: password,
        phone: phone,
        timezone: timezone,
    };

    return httpPost<unknown, MinimalClient>("/clients", body);
};

// MinimalClient
export const createCommsNurse = (
    commonName: string,
    givenName: string,
    surname: string,
    initials: string,
    email: string,
    password: string,
    phone: string | null,
    timezone: string,
    hospitalId: string,
    readOnly: boolean,
) => {
    const body = {
        type: "commsNurse",
        commonName: commonName,
        givenName: givenName,
        surname: surname,
        initials: initials,
        email: email,
        password: password,
        phone: phone,
        timezone: timezone,
        hospitalId: hospitalId,
        readOnly: readOnly,
    };
    return httpPost<unknown, MinimalClient>("/clients", body);
};

// null <204>
export const editClientInfo = (
    clientId: string,
    email: string | null,
    commonName: string | null,
    givenName: string | null,
    surname: string | null,
    phone: string | null,
    initials: string | null,
    avlId: string | null,
    sendAlertForEachUnit: boolean | null,
) => {
    const body = {
        email: email,
        commonName: commonName,
        givenName: givenName,
        surname: surname,
        phone: phone,
        initials: initials,
        avlId: avlId,
        sendAlertForEachUnit: sendAlertForEachUnit,
    };

    return httpPut<unknown, null>(`/clients/${clientId}`, body);
};

// ClientLocation | null
export const getClientLocation = (clientId: string) => {
    return httpGet<ClientLocation | null>(`/clients/${clientId}/location`);
};

// null <204>
export const updateClientLocation = (clientId: string, latitude: number, longitude: number) => {
    return httpPut(`/clients/${clientId}/location`, { latitude: latitude, longitude: longitude });
};

// null <204>
export const addClientToAgency = (
    clientId: string,
    agencyId: string,
    isManager: boolean,
    isDispatcher: boolean,
    addToAllUnits: boolean,
) => {
    const body = {
        agencyId: agencyId,
        isManager: isManager,
        isDispatcher: isDispatcher,
        addToAllUnits: addToAllUnits,
    };
    return httpPost<unknown, null>(`/clients/${clientId}/agencies`, body);
};

// null <204>
export const editClientAgencyMembership = (
    clientId: string,
    agencyId: string,
    isManager: EditableField<boolean>,
    isDispatcher: EditableField<boolean>,
) => {
    const body = {
        isManager: getEditableParam(isManager),
        isDispatcher: getEditableParam(isDispatcher),
    };
    return httpPut<unknown, null>(`/clients/${clientId}/agencies/${agencyId}`, body);
};

// null <204>
export const removeClientFromAgency = (clientId: string, agencyId: string) => {
    return httpDelete<null>(`/clients/${clientId}/agencies/${agencyId}`);
};

// null <204>
export const addClientToUnit = (clientId: string, unitId: string) => {
    const body = {
        unitId: unitId,
    };

    return httpPost<unknown, null>(`/clients/${clientId}/units`, body);
};

// null <204>
export const removeClientFromUnit = (clientId: string, unitId: string) => {
    return httpDelete<null>(`/clients/${clientId}/units/${unitId}`);
};

// PreAlertCheck
export const getPreAlertCheck = (clientId: string) => {
    return httpGet<PreAlertCheck>(`/clients/${clientId}/pre-alert-check`);
};

// AlertCheckIssue[]
export const getAlertCheck = (clientId: string, membershipId: string, deviceId: string) => {
    return httpGet<AlertCheckIssue[]>(`/clients/${clientId}/alert-check?membershipId=${membershipId}&deviceId=${deviceId}`);
};

// ClientEmailLogItem[]
export const getClientEmailLog = (clientId: string) => {
    return httpGet<ClientEmailLogItem[]>(`/clients/${clientId}/email-log`);
};

// Grave[]
export const getClientGraves = (clientId: string) => {
    return httpGet<Grave[]>(`/clients/${clientId}/graves`);
};

// null <204>
export const setClientStatus = (clientId: string, status: ClientStatusEnum, reason: string) => {
    const body = { reason, status };
    return httpPut<unknown, null>(`/clients/${clientId}/status`, body);
};
