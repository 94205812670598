/* eslint-disable no-console */
export const logger = {
    log(message: unknown, ...optionalParams: unknown[]) {
        console.log(message, ...optionalParams);
    },

    warn(message: unknown, ...optionalParams: unknown[]) {
        console.warn(message, ...optionalParams);
    },

    error(error: Error, context?: Record<string, unknown>) {
        console.error(error, context);
    },

    debug(message: unknown, ...optionalParams: unknown[]) {
        console.debug(message, ...optionalParams);
    },

    alert(message: unknown) {
        window.alert(message);
    },
};
