import { Grid, Title } from "@mantine/core";
import { IconBuilding, IconUser, IconUsersGroup } from "@tabler/icons-react";
import { CountWidget } from "pages/dashboard/widgets/CountWidget";
import { GraphWidget } from "pages/dashboard/widgets/GraphWidget";
import { HTMLProps } from "react";
import { Dashboard } from "shared/types/bryx911/Dashboard";

export type WidgetContainerProps = { data: Dashboard } & Omit<HTMLProps<HTMLDivElement>, "data">;

export const WidgetContainer = (props: WidgetContainerProps) => {
    function lastElem<T>(arr: T[]) {
        return arr[arr.length - 1];
    }

    return (
        <Grid grow gutter={32}>
            <Grid.Col span={12}>
                <Title order={2}>Year-To-Date Statistics</Title>
            </Grid.Col>
            <CountWidget title={"User Count"} count={lastElem(props.data.userCounts).count} icon={<IconUser />} />
            <CountWidget title={"Agency Count"} count={lastElem(props.data.agencyCounts).count} icon={<IconBuilding />} />
            <CountWidget title={"Membership Count"} count={lastElem(props.data.agencyMembershipCounts).count} icon={<IconUsersGroup />} />
            <GraphWidget title={"User Count"} dateCount={props.data.userCounts} />
            <GraphWidget title={"Agency Count"} dateCount={props.data.agencyCounts} />
            <GraphWidget title={"Membership Count"} dateCount={props.data.agencyMembershipCounts} />
        </Grid>
    );
};
