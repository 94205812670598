import { ActionIcon } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import { useState } from "react";

const CopyButton = ({ text }: { text: string }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    };

    const onClick = () => {
        copyTextToClipboard(text)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    };
    return (
        <ActionIcon variant={"outline"} color={"gray"} size={"lg"} onClick={onClick} disabled={isCopied}>
            <IconCopy size={16} />
        </ActionIcon>
    );
};

export default CopyButton;
