import { Text } from "@mantine/core";
import { ScuStatusAdornmentTag } from "pages/scus/ScuStatusAdornmentTag";
import { useNavigate } from "react-router-dom";
import { getScus } from "shared/api/bryx911/scusApi";
import CardSearchPage from "shared/components/CardSearchPage";
import { SearchResultCard } from "shared/components/SearchResultCard";
import { AgencyScuUnit } from "shared/types/bryx911/Agencies";

const SearchScus = () => {
    const navigate = useNavigate();

    const agenciesElements = (agencies: AgencyScuUnit[]) => (
        <Text variant="body-small">
            {agencies.length == 0 ? (
                "No Agencies"
            ) : (
                <>
                    {agencies.map((unit, index) => (
                        <span key={unit.id}>
                            {unit.name}
                            {index < agencies.length - 1 ? ", " : ""}
                        </span>
                    ))}
                </>
            )}
        </Text>
    );

    return (
        <CardSearchPage
            queryKey={"searchScus"}
            queryFn={getScus}
            heading={"SCUs"}
            zeroStateLabel={"SCU"}
            createModal={<div />}
            setCreateModalOpen={() => {
                /* no op */
            }}
            cardContentFn={(v) => (
                <SearchResultCard
                    key={v.id}
                    heading={v.name}
                    subheading={v.serial}
                    content={agenciesElements(v.units)}
                    onClick={() => navigate(`/scus/${v.id}`)}
                    adornment={<ScuStatusAdornmentTag status={v.status} />}
                />
            )}
        />
    );
};

export default SearchScus;
