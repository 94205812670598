import { useNavigate } from "react-router-dom";
import { getAgencies } from "shared/api/bryx911/agenciesApi";
import CardSearchPage from "shared/components/CardSearchPage";
import { SearchResultCard } from "shared/components/SearchResultCard";

const SearchAgencies = () => {
    const navigate = useNavigate();

    return (
        <CardSearchPage
            queryKey={"searchAgencies"}
            queryFn={getAgencies}
            heading={"Agencies"}
            createModal={<div />}
            setCreateModalOpen={() => {
                /* no op */
            }}
            zeroStateLabel={"agency"}
            cardContentFn={(v) => (
                <SearchResultCard key={v.id} heading={v.name} subheading={v.agencyKey} onClick={() => navigate(`/agencies/${v.id}`)} />
            )}
        />
    );
};

export default SearchAgencies;
