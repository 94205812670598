import "@mantine/core/styles.css";
import "shared/index.css";

import { MantineProvider } from "@mantine/core";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { router } from "app/Router";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { useColorMode } from "shared/hooks/useColorMode";
import { cssVariablesResolver, theme } from "shared/mantine/theme";
import { createToast } from "shared/notifications/createToast";
import { logger } from "shared/utils/logger";

interface QueryCacheQuery {
    meta?: {
        errorMessage?: string;
        errorCallback?: () => void;
    };
}

const queryClient = new QueryClient({
    // Default options are overridable on a per query/mutation basis
    defaultOptions: {
        queries: {
            retry: false,
            // throwOnError: true,
            refetchOnWindowFocus: true,
        },
        mutations: {
            retry: false,
            throwOnError: true,
        },
    },
    // Please do not add a `onSuccess` handler to the queryCache. Whatever you're attempting to do is likely
    // an anti-pattern or abuse of the library. `useQuery` mental model should be that it is a remote state management hook,
    // if you _absolutely_ need to sync the remote state with local state (ex: zustand) then opt for an effect via `useEffect`.
    queryCache: new QueryCache({
        onError: (error, query: QueryCacheQuery) => {
            logger.error(error);
            createToast({
                variant: "error",
                title: "Error",
                children: query.meta?.errorMessage ?? "Something went wrong.",
            });
            query.meta?.errorCallback?.();
        },
    }),
});

const Root = () => {
    const colorMode = useColorMode();

    return (
        <MantineProvider forceColorScheme={colorMode} theme={theme} cssVariablesResolver={cssVariablesResolver}>
            <RouterProvider router={router} />
        </MantineProvider>
    );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
            <Root />
            <ReactQueryDevtools />
        </QueryClientProvider>
    </StrictMode>,
);
