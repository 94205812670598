import { Alert, Title } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import useLoginStore from "app/login/LoginStore";
import HttpStatusCode from "shared/types/Http";

const ErrorComponent = ({ title, message }: { title: string; message: string }) => (
    <Alert title={<Title order={3}>{title}</Title>} icon={<IconExclamationCircle />}>
        {message}
    </Alert>
);

const GenericError = () => <ErrorComponent title="Oh no!" message="It looks like your email or password is incorrect." />;
const LockedError = () => (
    <ErrorComponent title="Account Locked" message="Your account has been locked. Please try again in 15 minutes or reset your password." />
);

const LoginError = () => {
    const { error } = useLoginStore(({ error }) => ({ error }));

    if (error?.status == HttpStatusCode.LOCKED) {
        return <LockedError />;
    }

    return <GenericError />;
};

export default LoginError;
