import { Button, Group, Modal, Text, Title } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Dispatch, ReactNode, SetStateAction } from "react";

export type ConfirmationModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onClose: (confirmed: boolean) => void;
    title?: string;
    prompt?: ReactNode;
};

export const ConfirmationModal = ({ open, setOpen, title, prompt, onClose }: ConfirmationModalProps) => (
    <Modal opened={open} onClose={() => setOpen(false)} title={<Title order={2}>{title ?? "Confirm Action"}</Title>} centered size={"xl"}>
        {prompt ?? <Text>Are you sure you want to perform this action?</Text>}
        <Group w={"100%"} justify={"flex-end"}>
            <Group gap={8} mt={16}>
                <Button
                    variant="primary"
                    leftSection={<IconX />}
                    onClick={() => {
                        setOpen(false);
                        onClose(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="secondary"
                    leftSection={<IconCheck />}
                    onClick={() => {
                        setOpen(false);
                        onClose(true);
                    }}
                >
                    Confirm
                </Button>
            </Group>
        </Group>
    </Modal>
);
