// Adapted from https://usehooks-ts.com/react-hook/use-copy-to-clipboard

import { createToast } from "shared/notifications/createToast";

type CopyFn = (text: string) => Promise<boolean>;
type FailFn = (failMsg: string) => void;

export function useCopyToClipboard(failFn?: FailFn): CopyFn {
    const safeFailFn = failFn ?? ((msg: string) => createToast({ variant: "error", title: "Error", children: msg }));

    return async (text) => {
        if (!navigator?.clipboard) {
            safeFailFn("Browser does not support clipboard access");
            return false;
        }

        try {
            await navigator.clipboard.writeText(text);
            return true;
        } catch (error) {
            safeFailFn(`Error copying to clipboard`);
            return false;
        }
    };
}
