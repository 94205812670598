import { httpDelete, httpGet, httpPost } from "shared/api/httpClient";
import { ApiKey, ApiKeyParameters, ApiKeyPermission } from "shared/types/bryx911/ApiKeys";

export const getApiKeys = (query: string) => {
    return httpGet<ApiKey[]>(`/api-keys?query=${query}`);
};

export const getApiKey = (id: string) => {
    return httpGet<ApiKey>(`/api-keys/${id}`);
};

export const createApiKey = (key: ApiKeyParameters) => {
    return httpPost<ApiKeyParameters, ApiKey>("/api-keys", key);
};

export const deleteApiKey = (id: string) => {
    return httpDelete<null>(`/api-keys/${id}`);
};

export const addPermissionToApiKey = (id: string, permission: ApiKeyPermission) => {
    return httpPost<ApiKeyPermission, null>(`/api-keys/${id}/permissions`, permission);
};

export const deletePermissionFromApiKey = (id: string, permission: ApiKeyPermission) => {
    return httpDelete<null>(`/api-keys/${id}/permissions/${permission.type}`);
};
