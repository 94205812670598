import { Card, CardProps, Stack, Title } from "@mantine/core";

export const SearchResultCard = ({
    heading,
    subheading,
    adornment,
    content,
    onClick,
    ...rest
}: { heading: string; subheading: string; content?: React.ReactElement; adornment?: React.ReactElement; onClick: () => void } & Omit<
    CardProps,
    "withBorder" | "shadow" | "p" | "h" | "radius"
>) => (
    <Card onClick={onClick} withBorder shadow={"sm"} p={32} h={"14rem"} radius={"md"} style={{ cursor: "pointer" }} {...rest}>
        <Stack justify={"space-between"} h={"100%"}>
            <Card.Section>
                <Stack gap={8}>
                    <Title order={3}>{heading}</Title>
                    <Title order={4}>{subheading}</Title>
                    <div style={{ display: "flex", maxHeight: "5rem", overflow: "overlay", scrollbarWidth: "none" }}>{content}</div>
                </Stack>
            </Card.Section>
            {adornment && <Card.Section>{adornment}</Card.Section>}
        </Stack>
    </Card>
);
