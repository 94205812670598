import { Button, Group, Modal, Stack, TextInput, Title } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { AudioStream } from "shared/types/bryx911/AudioStream";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export type SpecificStreamModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    audioStream?: AudioStream;
    onSave: (s: AudioStream | Omit<AudioStream, "id">) => void;
};

const useStreamStore = create<Omit<AudioStream, "id"> & { setName: (s: string) => void; setUrl: (s: string) => void }>()(
    devtools(
        immer((set) => ({
            name: "",
            url: "",
            setName: (name) => set({ name }),
            setUrl: (url) => set({ url }),
        })),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "Audio Streams" },
    ),
);

export const SpecificStreamModal = ({ setOpen, open, audioStream, onSave }: SpecificStreamModalProps) => {
    const { name, url, setName, setUrl } = useStreamStore();

    useEffect(() => {
        setName(audioStream?.name ?? "");
        setUrl(audioStream?.url ?? "");
    }, [audioStream]);

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Title order={1}>{`${audioStream ? "Edit" : "Create"} Audio Stream`}</Title>}
            centered
            size={"xl"}
        >
            <Stack gap={16}>
                <TextInput label="Name" placeholder="Stream name..." value={name} onChange={(e) => setName(e.target.value)} />
                <TextInput label="URL" placeholder="Stream URL..." value={url} onChange={(e) => setUrl(e.target.value)} />
            </Stack>
            <Group w={"100%"} justify={"flex-end"}>
                <Group gap={8} mt={16}>
                    <Button variant="primary" leftSection={<IconX />} onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="secondary" leftSection={<IconCheck />} onClick={() => onSave({ ...audioStream, name, url })}>
                        Save
                    </Button>
                </Group>
            </Group>
        </Modal>
    );
};
