import { Nullable } from "@tsly/maybe";
import { HttpError } from "shared/types/Http";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type LoginStore = {
    email: string;
    password: string;
    token: string;
    hasError: boolean;
    error: Nullable<HttpError>;
    setEmail: (s: string) => void;
    setPassword: (s: string) => void;
    setToken: (s: string) => void;
    setError: (e: HttpError) => void;
    clearForm: () => void;
};

const loginStoreDefault = {
    email: "",
    password: "",
    token: "",
    error: null,
    hasError: false,
};

const useLoginStore = create<LoginStore>()(
    devtools(
        immer((set) => ({
            ...loginStoreDefault,
            setEmail: (email) => set({ email }),
            setPassword: (password) => set({ password }),
            setToken: (token) => set({ token }),
            setError: (error) =>
                set({
                    error,
                    hasError: !!error,
                }),
            clearForm: () => set(loginStoreDefault),
        })),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "Login" },
    ),
);

export default useLoginStore;
