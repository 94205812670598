import { httpDelete, httpGet, httpPost, httpPut } from "shared/api/httpClient";
import { AudioStream } from "shared/types/bryx911/AudioStream";

export const getStreams = (query: string) => {
    return httpGet<AudioStream[]>(`/streams?query=${query}`);
};

export const getStream = (id: string) => {
    return httpGet<AudioStream>(`/streams/${id}`);
};

export const updateStream = (id: string, name: string, url: string) => {
    return httpPut<Omit<AudioStream, "id">, void>(`/streams/${id}`, { name, url });
};

export const createStream = (name: string, url: string) => {
    return httpPost<Omit<AudioStream, "id">, void>(`/streams`, { name, url });
};

export const deleteStream = (id: string) => {
    return httpDelete<null>(`/streams/${id}`);
};
