import { ActionIcon, Button, Center, Checkbox, Group, Loader, Modal, TextInput, Title } from "@mantine/core";
import { IconCheck, IconRefresh, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SCU_QUERY_KEY } from "pages/scus/SpecificScu";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { setScuPassword } from "shared/api/bryx911/scusApi";
import { useCopyToClipboard } from "shared/hooks/useClipboard";
import { createToast } from "shared/notifications/createToast";
import { FullScu } from "shared/types/bryx911/Scu";
import { generatePassword } from "shared/utils/scus";

export type SetScuPasswordModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    scu?: FullScu;
};

export const SetScuPasswordModal = ({ setOpen, open, scu }: SetScuPasswordModalProps) => {
    const queryClient = useQueryClient();
    const copy = useCopyToClipboard();

    const [password, setPassword] = useState("");
    const [copyOnSet, setCopyOnSet] = useState(false);

    useEffect(() => {
        // reset password field to blank when closed.
        // do it when closed to prevent any 'flicker' from a rerender on open
        !open && setPassword("");
    }, [open]);

    const { mutate: setPasswordMutation, isPending } = useMutation({
        throwOnError: false,
        mutationFn: (password: string) => setScuPassword(scu!.id, password),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [SCU_QUERY_KEY, scu?.id] });
            if (copyOnSet && scu!.email != null) {
                void copy(
                    JSON.stringify({
                        user: scu!.email,
                        password: password,
                        url: `${import.meta.env.VITE_API_BASE_URL_NON_SUPPORT}/`,
                    }),
                ).then(() => createToast({ variant: "success", title: "Copied", children: "Copied SCU API config to clipboard" }));
            }
            setOpen(false);
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Setting password failed" });
        },
    });

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Title order={1}>{`Set ${scu?.name} Password`}</Title>}
            centered
            size={"xl"}
        >
            {!scu ? (
                <Center>
                    <Loader color={"red"} size={"lg"} />
                </Center>
            ) : (
                <TextInput
                    label={"Password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    rightSection={
                        <ActionIcon type={"button"} onClick={() => setPassword(generatePassword(10))}>
                            <IconRefresh />
                        </ActionIcon>
                    }
                />
            )}
            <Group w={"100%"} justify={"flex-end"} gap={8} mt={16}>
                {isPending ? (
                    <Center>
                        <Loader color={"red"} />
                    </Center>
                ) : (
                    <>
                        <Checkbox
                            label="Copy JSON on Set"
                            alt="Copy JSON on Set"
                            checked={copyOnSet}
                            disabled={isPending}
                            onChange={(e) => setCopyOnSet(e.target.checked)}
                            mr={8}
                        />
                        <Button variant="secondary" leftSection={<IconX />} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            leftSection={<IconCheck />}
                            disabled={!password}
                            onClick={() => setPasswordMutation(password)}
                        >
                            Save
                        </Button>
                    </>
                )}
            </Group>
        </Modal>
    );
};
