import { Paper, Stack, Tabs, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ComponentType, LazyExoticComponent, ReactNode, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageSpinner } from "shared/components/PageSpinner";
import PageWrapper from "shared/components/PageWrapper";

export type TabbedEntityPageTabList<TEntity> = {
    slug: string;
    name: string;
    component: LazyExoticComponent<ComponentType<{ entity: TEntity }>>;
    countFn?: (entity: TEntity) => number;
}[];

export function TabbedEntityPage<TEntity>({
    id,
    tab,
    tabs,
    slug,
    fetchFn,
    queryKey,
    headingTextFn,
    adornmentFn,
}: {
    id: string;
    tab?: string;
    tabs: TabbedEntityPageTabList<TEntity>;
    slug: string;
    fetchFn: (id: string) => Promise<TEntity>;
    queryKey: string;
    headingTextFn: (entity: TEntity) => ReactNode;
    adornmentFn?: (entity: TEntity) => ReactNode;
}) {
    const navigate = useNavigate();
    const location = useLocation();

    const { data, isLoading } = useQuery({
        queryKey: [queryKey, id],
        queryFn: () => fetchFn(id),
    });

    const defaultSlug = tabs[0].slug;
    const indexOfTab = () => tabs.map((t) => t.slug).indexOf(tab ?? defaultSlug);

    if (indexOfTab() < 0) {
        navigate(`${location.pathname}/${defaultSlug}`);
        return;
    }

    const ActiveTabComponent = tabs[indexOfTab()].component;

    if (!data || isLoading) {
        return <PageSpinner />;
    }

    const heading = headingTextFn(data);

    return (
        <PageWrapper>
            <Stack gap={16}>
                {typeof heading == "string" ? <Title order={1}>{heading}</Title> : heading}
                {adornmentFn && adornmentFn(data)}
                <Tabs defaultValue={tabs[indexOfTab()].slug}>
                    <Tabs.List>
                        {tabs.map((tab) => (
                            <Tabs.Tab
                                key={tab.slug}
                                value={tab.slug}
                                size={"lg"}
                                onClick={() => {
                                    navigate(`/${slug}/${id}/${tab.slug}`, { state: location.state as string });
                                }}
                                rightSection={
                                    tab.countFn && (
                                        <Paper p={8} ml={"0.25rem"} m={"auto"}>
                                            <Text fw={"bolder"}>{tab.countFn(data)}</Text>
                                        </Paper>
                                    )
                                }
                            >
                                {tab.name}
                            </Tabs.Tab>
                        ))}
                    </Tabs.List>
                </Tabs>
                <Suspense fallback={<PageSpinner />}>
                    <ActiveTabComponent entity={data} />
                </Suspense>
            </Stack>
        </PageWrapper>
    );
}

export default TabbedEntityPage;
