import { z } from "zod";

const EditableFieldValueSchema = (value: z.ZodType) =>
    z.object({
        type: z.literal("set"),
        value,
    });

const EditableFieldIgnoreSchema = z.object({
    type: z.literal("clear"),
});

export const EditableFieldSchema = (value?: z.ZodType) => (value ? EditableFieldValueSchema(value) : EditableFieldIgnoreSchema);
export type EditableField<T> = { type: "set"; value: T } | { type: "clear" };
export type ZodEditableField<T> = { type: "set"; value?: T } | { type: "clear" };

export const MinimalNamedPairSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export type MinimalNamedPair = z.infer<typeof MinimalNamedPairSchema>;

export function getEditableParam<T, U>(field: EditableField<T>, transform?: (value: T) => U): T | U | null {
    const finalTransform = transform != null ? transform : (v: T) => v;
    switch (field.type) {
        case "set":
            return finalTransform(field.value);
        case "clear":
            return null;
    }
}

export const ApiTimestampSchema = z.preprocess((n) => new Date(Number(n) / 1000), z.date());
export const apiTimestampToDate = (n: number) => new Date(n * 1000);
