import { isSome, maybe, Nullable } from "@tsly/maybe";
import { useEffect, useRef, useState } from "react";

/** Debounces some watched value by introducing a delay between when the watched value changes to when the returned value changes */
export function useDebouncedValue<T>(
    watchedValue: T,
    options: {
        /** The duration of the delay in milliseconds. Defaults to `100` if not set */
        delayMilliseconds?: number;
        /** When set, the debounce will only apply when the `watchedValue` changes to a member of the `forValues` allowlist */
        forValues?: T[];
        /** Values which, if the `watchedValue` is changed to, will not be debounced. Note that this takes precedence over the `forValues` allowlist */
        exceptValues?: T[];
    } = {},
) {
    const [smoothedValue, setSmoothedValue] = useState(watchedValue);
    const { delayMilliseconds = 100, forValues = [], exceptValues = [] } = options;

    const timeout = useRef<Nullable<NodeJS.Timeout>>(null);

    useEffect(() => {
        if (exceptValues.includes(watchedValue)) return;
        if (forValues.length > 0 && !forValues.includes(watchedValue)) return;

        isSome(timeout.current) && clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            setSmoothedValue(watchedValue);
        }, delayMilliseconds);

        return () => void maybe(timeout.current)?.also((it) => clearTimeout(it));
    }, [watchedValue]);

    return smoothedValue;
}
