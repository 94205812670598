import { Center, Combobox, Loader, TextInput, TextInputProps, Title, useCombobox } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useDebouncedValue } from "shared/hooks/useDebouncedValue";

export type SearchWithResultsProps<T extends { id: string }> = {
    queryKey: string;
    queryFn: (s: string) => Promise<T[]>;
    filterFn?: (obj: T) => boolean;
    getDisplayValueForResult: (result: T) => React.ReactElement;
} & Omit<TextInputProps, "onChange" | "value" | "setValue">;

const SearchWithResults = <T extends { id: string }>({
    queryFn,
    queryKey,
    filterFn,
    getDisplayValueForResult,
    placeholder = "Select...",
    ...rest
}: SearchWithResultsProps<T>) => {
    const [query, setQuery] = useState<string>("");
    const debouncedQuery = useDebouncedValue(query, { delayMilliseconds: 250 });

    const { isLoading, data } = useQuery({
        queryKey: [queryKey, debouncedQuery],
        queryFn: () => queryFn(debouncedQuery ?? ""),
        enabled: debouncedQuery != "",
    });

    const combobox = useCombobox({
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            setQuery("");
        },

        onDropdownOpen: () => {
            combobox.focusSearchInput();
        },
    });

    const filteredData = data?.filter(filterFn ?? ((_) => true));

    return (
        <Combobox
            store={combobox}
            position="top-start"
            withinPortal={false}
            onOptionSubmit={() => {
                combobox.closeDropdown();
            }}
        >
            <Combobox.Target withAriaAttributes={false}>
                <TextInput
                    {...rest}
                    placeholder={placeholder}
                    value={query}
                    onChange={(event) => {
                        setQuery(event.currentTarget.value);
                        combobox.openDropdown();
                        combobox.updateSelectedOptionIndex();
                    }}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                />
            </Combobox.Target>
            {!!query && (
                <Combobox.Dropdown>
                    {debouncedQuery != query || isLoading ? (
                        <Center>
                            <Loader color={"red"} />
                        </Center>
                    ) : filteredData && filteredData.length > 0 ? (
                        <Combobox.Options>
                            {filteredData?.map((d) => (
                                <Combobox.Option key={d.id} value={d.id}>
                                    {getDisplayValueForResult(d)}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    ) : (
                        <Center p={32}>
                            <Title order={3}>No Results</Title>
                        </Center>
                    )}
                </Combobox.Dropdown>
            )}
        </Combobox>
    );
};

export default SearchWithResults;
