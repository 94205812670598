import { FieldValues } from "react-hook-form";
import { FieldNamesMarkedBoolean } from "react-hook-form/dist/types/form";
import { EditableField, ZodEditableField } from "shared/types/ApiTypes";

export function valueToEditable<T>(value: T): EditableField<T> {
    return value == "" || value == null ? { type: "clear" } : { type: "set", value };
}

export function unpackEditable<T>(value: ZodEditableField<T> | EditableField<T>): T | null {
    return value.type == "clear" || value.value == undefined ? null : value.value;
}

export function nullIfBlank(x: string | null | undefined): string | null {
    return x && x != "" ? x : null;
}

export function getDirtyValues<T extends FieldValues, U extends FieldValues>(
    dirtyFields: Partial<FieldNamesMarkedBoolean<T>>,
    values: T,
    initialValue: U,
): Partial<T> {
    return Object.keys(dirtyFields).reduce((prev, key) => {
        const typedKey = key as keyof Partial<FieldNamesMarkedBoolean<T>>;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        if (dirtyFields[typedKey] && !values[key]) return { [key]: initialValue ? initialValue[key] : null };
        // Unsure when RFH sets this to `false`, but omit the field if so.
        if (!dirtyFields[typedKey] || !values[key]) return prev;

        if (typeof dirtyFields[typedKey] === "object") {
            const inner = getDirtyValues(
                dirtyFields[typedKey] as Partial<FieldNamesMarkedBoolean<T>>,
                values[key],
                initialValue ? initialValue[key] : undefined,
            );
            if (Object.keys(inner).length == 0) {
                return prev;
            }
            return {
                ...prev,
                [key]: inner,
            };
        }

        return {
            ...prev,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [key]: values[key],
        };
    }, {});
}
