import { Flex, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { WidgetContainer } from "pages/dashboard/widgets/BaseWidgets";
import { getDashboard } from "shared/api/dashboardApi";
import { PageSpinner } from "shared/components/PageSpinner";

const DashboardPage = () => {
    const { data: dashboard, isLoading } = useQuery({
        queryKey: ["dashboard"],
        queryFn: () => getDashboard(),
        meta: {
            errorMessage: "Failed to retrieve dashboard",
        },
    });

    if (isLoading || !dashboard) {
        return <PageSpinner />;
    }

    return (
        <Flex direction={"column"}>
            <Flex direction={"column"} gap={"2rem"} justify={"space-between"} px={"3rem"} pt={"1rem"} pb={"3rem"}>
                <Title order={1}>Bryx 911 Dashboard</Title>
            </Flex>
            <div style={{ width: "100%", padding: "0 3rem" }}>
                <WidgetContainer data={dashboard} />
            </div>
        </Flex>
    );
};

export default DashboardPage;
