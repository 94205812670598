import { DetailedHTMLProps, HTMLAttributes, useEffect } from "react";
import { useColorMode } from "shared/hooks/useColorMode";

export const ColorModeProvider = ({
    children,
    ...rest
}: Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "className">) => {
    const colorModeClassName = useColorMode();

    useEffect(() => {
        if (colorModeClassName === "dark") {
            document.body.classList.remove("light");
            document.body.classList.add(colorModeClassName);
        } else {
            document.body.classList.remove("dark");
            document.body.classList.add(colorModeClassName);
        }
    }, [colorModeClassName]);

    return (
        <div {...rest} className={colorModeClassName}>
            {children}
        </div>
    );
};
