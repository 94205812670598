import { Button, Center, Flex, SimpleGrid, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageWrapper from "shared/components/PageWrapper";
import SearchBar from "shared/components/SearchBar";

export function CardSearchPage<T>({
    queryKey,
    queryFn,
    heading,
    zeroStateLabel,
    cardContentFn,
    createModal,
    setCreateModalOpen,
}: {
    queryKey: string;
    queryFn: (query: string) => Promise<T[]>;
    heading: string;
    zeroStateLabel: string;
    cardContentFn: (obj: T) => ReactNode;
    createModal?: ReactElement;
    setCreateModalOpen?: (open: boolean) => void;
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get("q");

    const [searchInput, setSearchInput] = useState<string>(searchQuery || "");

    const { data, isLoading } = useQuery({
        queryKey: [queryKey, searchQuery],
        queryFn: () => queryFn(searchQuery!),
        enabled: searchQuery != "",
    });

    const submitSearch = () => searchInput && setSearchParams({ q: searchInput });

    return (
        <PageWrapper>
            {createModal}
            <Flex direction={"column"} gap={24}>
                <Flex justify={"space-between"}>
                    <Title order={2}>Search {heading}</Title>
                    {createModal && setCreateModalOpen && (
                        <Button color={"red"} onClick={() => setCreateModalOpen(true)}>
                            Create
                        </Button>
                    )}
                </Flex>
                <Flex direction={"column"} w={"100%"} gap={32}>
                    <SearchBar
                        submit={submitSearch}
                        value={searchInput}
                        placeholder="Search..."
                        onChange={(e) => setSearchInput(e.target.value)}
                        autoFocus
                        loading={!!searchQuery && isLoading}
                    />
                    {!searchQuery && (
                        <Text variant="body-small">
                            Use the search box above to search for a{zeroStateLabel.match(/^[aeiouy].*/i) && "n"} {zeroStateLabel}.
                        </Text>
                    )}
                </Flex>
                {!!searchQuery && !isLoading && data?.length == 0 ? (
                    <Center>
                        <Title order={3}>No Results</Title>
                    </Center>
                ) : (
                    <SimpleGrid cols={{ base: 1, sm: 2, lg: 5 }}>{data?.map((v) => cardContentFn(v))}</SimpleGrid>
                )}
            </Flex>
        </PageWrapper>
    );
}

export default CardSearchPage;
