import SearchAgencies from "pages/agencies/SearchAgencies";
import SpecificAgency from "pages/agencies/SpecificAgency";
import { useParams } from "react-router-dom";
import { match, P } from "ts-pattern";

const AgenciesPage = () => {
    const { id, tab } = useParams();

    return match(id)
        .with(P.string, (id) => <SpecificAgency id={id} tab={tab} />)
        .otherwise(() => <SearchAgencies />);
};

export default AgenciesPage;
