import SearchClients from "pages/clients/SearchClients";
import SpecificClient from "pages/clients/SpecificClient";
import { useParams } from "react-router-dom";
import { match, P } from "ts-pattern";

const ClientsPage = () => {
    const { id, tab } = useParams();

    return match(id)
        .with(P.string, (id) => <SpecificClient id={id} tab={tab} />)
        .otherwise(() => <SearchClients />);
};

export default ClientsPage;
