import { lazy } from "react";
import { getClient } from "shared/api/bryx911/clientsApi";
import TabbedEntityPage from "shared/components/TabbedEntityPage";

const SpecificClient = ({ id, tab }: { id: string; tab?: string }) => (
    <TabbedEntityPage
        id={id}
        tab={tab}
        slug={"clients"}
        fetchFn={getClient}
        queryKey={"getClient"}
        headingTextFn={(client) => client.commonName}
        tabs={[
            { slug: "agencies", name: "Agencies", component: lazy(() => import("./specific/ClientAgenciesTab")) },
            { slug: "devices", name: "Devices", component: lazy(() => import("./specific/DevicesTab")) },
            { slug: "requests", name: "Requests", component: lazy(() => import("./specific/RequestsTab")) },
            { slug: "alert-check", name: "Alert Check", component: lazy(() => import("./specific/AlertCheckTab")) },
            { slug: "graves", name: "Graves", component: lazy(() => import("./specific/GravesTab")) },
        ]}
    />
);

export default SpecificClient;
