import { Chip } from "@mantine/core";
import { IconCheck, IconCircleOff, IconClock, IconDeviceSpeaker, IconX } from "@tabler/icons-react";
import { ScuStatusEnum } from "shared/types/bryx911/Scu";
import { match } from "ts-pattern";

export const ScuStatusAdornmentTag = ({ status }: { status: ScuStatusEnum }) =>
    match(status)
        .with("audio", () => (
            <Chip defaultChecked radius={"sm"} color={"yellow"} icon={<IconDeviceSpeaker size={16} />}>
                Audio
            </Chip>
        ))
        .with("idle", () => (
            <Chip defaultChecked radius={"sm"} color={"green.5"} icon={<IconCheck size={16} />}>
                Idle
            </Chip>
        ))
        .with("turnout", () => (
            <Chip defaultChecked radius={"sm"} color={"yellow"} icon={<IconClock size={16} />}>
                Turning Out
            </Chip>
        ))
        .with("offline", () => (
            <Chip defaultChecked radius={"sm"} color={"red.4"} icon={<IconX size={16} />}>
                Offline
            </Chip>
        ))
        .with("disabled", () => (
            <Chip defaultChecked radius={"sm"} color={"red.4"} icon={<IconCircleOff size={16} />}>
                Disabled
            </Chip>
        ))
        .exhaustive();
