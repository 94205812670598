import { httpDelete, httpPost } from "shared/api/httpClient";
import { UserAccount } from "shared/types/UserAccount";

export const signIn = (username: string, password: string, token: string) => {
    const authBody = {
        username,
        password,
        totp: token,
    };
    return httpPost<unknown, UserAccount>(`/authorization`, authBody);
};

export const signOut = () => {
    return httpDelete("/authorization");
};
