import { Button, Group, Text, Title } from "@mantine/core";
import { IconCpu, IconError404, IconFiretruck, IconLocation, IconMail, IconServer, IconTerminal, IconVersions } from "@tabler/icons-react";
import { EditBasicInfoModal } from "pages/scus/modals/EditBasicInfoModal";
import { ExportScuConfigModal } from "pages/scus/modals/ExportScuConfigModal";
import { ImportScuConfigModal } from "pages/scus/modals/ImportScuConfigModal";
import { ResetScuModal } from "pages/scus/modals/ResetScuModal";
import { SetScuPasswordModal } from "pages/scus/modals/SetPasswordModal";
import { ScuStatusAdornmentTag } from "pages/scus/ScuStatusAdornmentTag";
import { lazy, useState } from "react";
import { getScu } from "shared/api/bryx911/scusApi";
import { ActionMenu } from "shared/components/ActionMenu";
import { AdornmentTag, DualAdornmentTag } from "shared/components/DualAdornmentTag";
import TabbedEntityPage from "shared/components/TabbedEntityPage";
import { useCopyToClipboard } from "shared/hooks/useClipboard";
import { FullScu, ScuLocation, ScuModelEnum } from "shared/types/bryx911/Scu";
import { scuModelIdString, scuModelNameString } from "shared/utils/scus";
import { match } from "ts-pattern";

export const SCU_QUERY_KEY = "getScu";

const SpecificScu = ({ id, tab }: { id: string; tab?: string }) => {
    const [exportConfigModalOpen, setExportConfigModalOpen] = useState(false);
    const [importConfigModalOpen, setImportConfigModalOpen] = useState(false);
    const [editBasicInfoModelOpen, setEditBasicInfoModelOpen] = useState(false);
    const [setPasswordModalOpen, setSetPasswordModalOpen] = useState(false);
    const [resetScuModalOpen, setResetScuModalOpen] = useState(false);

    const copy = useCopyToClipboard();

    const scuLinkedLocationTag = (location: ScuLocation) =>
        match(location)
            .with({ type: "none" }, () => <AdornmentTag color={"red"} icon={<IconError404 />} text={"No Linked Location"} />)
            .with({ type: "station" }, (station) => (
                <DualAdornmentTag
                    color={"gray"}
                    variant="default"
                    icon={<IconFiretruck color={"white"} />}
                    primaryText={"Linked Station"}
                    secondaryText={station.addr}
                />
            ))
            .with({ type: "point" }, () => <AdornmentTag color={"gray"} icon={<IconLocation />} text={"Fixed Coordinates"} />)
            .exhaustive();

    const sshUser = (model: ScuModelEnum) =>
        match(model)
            .with("other", () => "pi")
            .with("p1", () => "root")
            .with("s1", () => "root")
            .otherwise(() => "bryx");

    return (
        <TabbedEntityPage
            id={id}
            tab={tab}
            slug={"scus"}
            fetchFn={getScu}
            queryKey={SCU_QUERY_KEY}
            headingTextFn={(scu: FullScu) => (
                <>
                    <ExportScuConfigModal open={exportConfigModalOpen} setOpen={setExportConfigModalOpen} scu={scu} />
                    <ImportScuConfigModal open={importConfigModalOpen} setOpen={setImportConfigModalOpen} scu={scu} />
                    <EditBasicInfoModal open={editBasicInfoModelOpen} setOpen={setEditBasicInfoModelOpen} scu={scu} />
                    <SetScuPasswordModal open={setPasswordModalOpen} setOpen={setSetPasswordModalOpen} scu={scu} />
                    <ResetScuModal open={resetScuModalOpen} setOpen={setResetScuModalOpen} scu={scu} />
                    <Group justify={"space-between"}>
                        <Group gap={12}>
                            <Title order={1}>{scu.name}</Title>
                            <Title mt={8} order={4}>
                                {scu.serial}
                            </Title>
                        </Group>
                        <ActionMenu
                            actions={{
                                "Export Config": () => setExportConfigModalOpen(true),
                                "Import Config": () => setImportConfigModalOpen(true),
                                "Edit Basic Info": () => setEditBasicInfoModelOpen(true),
                                // "Edit Dates": () => {},
                                "Set Password": () => setSetPasswordModalOpen(true),
                                "Reset SCU": () => setResetScuModalOpen(true),
                            }}
                        />
                    </Group>
                </>
            )}
            adornmentFn={(scu: FullScu) => (
                <Group gap={8}>
                    <ScuStatusAdornmentTag status={scu.status} />
                    <AdornmentTag color={"gray"} icon={<IconMail color={"white"} />} text={scu.email || "Null"} />
                    {scuLinkedLocationTag(scu.location)}
                    <DualAdornmentTag
                        color={"gray"}
                        variant="default"
                        icon={<IconServer color={"white"} />}
                        primaryText={scuModelNameString(scu.model)}
                        secondaryText={scuModelIdString(scu.model)}
                    />
                    <DualAdornmentTag
                        color={"gray"}
                        variant="default"
                        icon={<IconCpu color={"white"} />}
                        primaryText={scu.macAddress ?? "Unknown"}
                        secondaryText={"MAC"}
                    />
                    <DualAdornmentTag
                        color={"gray"}
                        variant="default"
                        icon={<IconVersions color={"white"} />}
                        primaryText={scu.swVersion ?? "Unknown"}
                        secondaryText={"Version"}
                    />
                    <ActionMenu
                        actions={{
                            "Copy Hostname": () => {
                                void copy(`sa-${scu.serial.toLowerCase()}`);
                            },
                            "Copy SSH Command": () => {
                                void copy(`ssh ${sshUser(scu.model)}@sa-${scu.serial.toLowerCase()}`);
                            },
                            "Open SSH...": () => {
                                window.location.href = `ssh://${sshUser(scu.model)}@sa-${scu.serial.toLowerCase()}`;
                            },
                            "Open rtty...": () => {
                                window.open(`https://backdoor.bryx.com:5913/rtty/sa-${scu.serial.toLowerCase()}`, "_blank");
                            },
                        }}
                        trigger={
                            <Button size={"compact-sm"} h={"1.75rem"} color={"dark.6"} leftSection={<IconTerminal color={"white"} />}>
                                <Text c={"white"}>Connect</Text>
                            </Button>
                        }
                    />
                </Group>
            )}
            tabs={[
                { slug: "agencies", name: "Agencies", component: lazy(() => import("./specific/ScuAgenciesTab")) },
                { slug: "health", name: "Health", component: lazy(() => import("./specific/HealthTab")) },
                { slug: "config", name: "Config", component: lazy(() => import("./specific/ConfigTab")) },
                { slug: "sash", name: "Sash", component: lazy(() => import("./specific/SashTab")) },
                { slug: "light-ping", name: "Light Ping", component: lazy(() => import("./specific/LightPingTab")) },
            ]}
        />
    );
};

export default SpecificScu;
