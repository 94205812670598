import { Center, Table, TableCaptionProps, TableTdProps } from "@mantine/core";
import { obj } from "@tsly/obj";
import classes from "shared/components/Tables.module.css";

export const ZeroStateCaption = ({ children, ...rest }: TableCaptionProps) => (
    <Table.Caption {...rest}>
        <Center className={classes.captionZeroState}>{children}</Center>
    </Table.Caption>
);

export const RightTd = ({ children, ...rest }: TableTdProps) => (
    <Table.Td {...obj(rest).dropKeys(["ta"]).take()} ta={"right"} pr={16}>
        {children}
    </Table.Td>
);
