import { Button, Flex, Paper, Table, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageSpinner } from "shared/components/PageSpinner";
import PageWrapper from "shared/components/PageWrapper";
import SearchBar from "shared/components/SearchBar";
import { ZeroStateCaption } from "shared/components/Tables";

export type TableSearchPageConfig<T> = {
    columns: string[];
    renderRow: (data: T) => JSX.Element;
};

export function TableSearchPage<T>({
    queryKey,
    queryFn,
    heading,
    zeroStateLabel,
    tableConfig,
    emptyTableLabel,
    createModal,
    setCreateModalOpen,
}: {
    queryKey: string;
    queryFn: (query: string) => Promise<T[]>;
    heading: string;
    zeroStateLabel: string;
    tableConfig: TableSearchPageConfig<T>;
    emptyTableLabel?: string;
    createModal?: ReactElement;
    setCreateModalOpen?: (open: boolean) => void;
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get("q");

    const [searchInput, setSearchInput] = useState<string>(searchQuery || "");

    const { data, isLoading } = useQuery({
        queryKey: [queryKey, searchQuery],
        queryFn: () => queryFn(searchQuery!),
        enabled: !!searchQuery,
    });

    const submitSearch = () => searchInput && setSearchParams({ q: searchInput });

    return (
        <PageWrapper>
            {createModal}
            <Flex direction={"column"} gap={24}>
                <Flex justify={"space-between"}>
                    <Title order={2}>Search {heading}</Title>
                    {createModal && setCreateModalOpen && (
                        <Button color={"red"} onClick={() => setCreateModalOpen(true)}>
                            Create
                        </Button>
                    )}
                </Flex>
                <Flex direction={"column"} w={"100%"} gap={32}>
                    <SearchBar
                        submit={submitSearch}
                        value={searchInput}
                        placeholder="Search..."
                        onChange={(e) => setSearchInput(e.target.value)}
                        autoFocus
                        loading={!!searchQuery && isLoading}
                    />
                    {!searchQuery && (
                        <Text variant="body-small">
                            Use the search box above to search for a{zeroStateLabel.match(/^[aeiouy].*/i) && "n"} {zeroStateLabel}.
                        </Text>
                    )}
                </Flex>
                {!!searchQuery && (
                    <Paper mt={16} p={16}>
                        <Table highlightOnHover withRowBorders>
                            {isLoading && (
                                <ZeroStateCaption>
                                    <PageSpinner />
                                </ZeroStateCaption>
                            )}
                            {data && data.length == 0 && (
                                <ZeroStateCaption>
                                    <Title order={6}>{emptyTableLabel}</Title>
                                </ZeroStateCaption>
                            )}
                            <Table.Thead tabIndex={-1}>
                                <Table.Tr>
                                    {tableConfig.columns.map((col, idx) => (
                                        <Table.Th key={idx}>
                                            <Title order={3}>{col}</Title>
                                        </Table.Th>
                                    ))}
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>{data?.map((entity) => tableConfig.renderRow(entity))}</Table.Tbody>
                        </Table>
                    </Paper>
                )}
            </Flex>
        </PageWrapper>
    );
}

export default TableSearchPage;
