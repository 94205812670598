import { Table } from "@mantine/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Nullable } from "@tsly/maybe";
import { SpecificStreamModal } from "pages/streams/SpecificStreamModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createStream, deleteStream, getStream, getStreams, updateStream } from "shared/api/bryx911/streamsApi";
import { ActionMenu } from "shared/components/ActionMenu";
import { ConfirmationModal } from "shared/components/ConfirmationModal";
import CopyButton from "shared/components/CopyButton";
import { RightTd } from "shared/components/Tables";
import TableSearchPage from "shared/components/TableSearchPage";
import { createToast } from "shared/notifications/createToast";
import { AudioStream } from "shared/types/bryx911/AudioStream";

const QUERY_KEY = "searchStreams";

const StreamTableRow = ({
    stream,
    openModal,
    promptDelete,
}: {
    stream: AudioStream;
    openModal: (stream: Nullable<AudioStream>) => void;
    promptDelete: (stream: AudioStream) => void;
}) => (
    <Table.Tr>
        <Table.Td>
            <CopyButton text={stream.id} />
        </Table.Td>
        <Table.Td>{stream.name}</Table.Td>
        <Table.Td>{stream.url}</Table.Td>
        <RightTd>
            <ActionMenu
                actions={{
                    "Edit Stream...": () => openModal(stream),
                    "Delete Stream...": () => promptDelete(stream),
                }}
            />
        </RightTd>
    </Table.Tr>
);

const StreamsPage = () => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [activeStream, setActiveStream] = useState<Nullable<AudioStream>>(null);

    const openModal = (stream: Nullable<AudioStream>) => {
        setActiveStream(stream);
        setModalOpen(true);
    };

    useEffect(() => {
        id &&
            getStream(id)
                .then((stream) => openModal(stream))
                .catch(() => createToast({ variant: "error", title: "Error", children: "Unable to open stream" }));
    }, [id]);

    const { mutate: updateStreamMutation } = useMutation({
        throwOnError: false,
        mutationFn: ({ id, name, url }: AudioStream) => updateStream(id, name, url),
        onSuccess: async () => {
            setModalOpen(false);
            createToast({ variant: "success", title: "Success", children: "Stream updated successfully" });
            await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Updating stream failed" });
        },
    });

    const { mutate: createStreamMutation } = useMutation({
        throwOnError: false,
        mutationFn: ({ name, url }: Omit<AudioStream, "id">) => createStream(name, url),
        onSuccess: async () => {
            setModalOpen(false);
            createToast({ variant: "success", title: "Success", children: "Stream created successfully" });
            await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Creating stream failed" });
        },
    });

    const { mutate: deleteStreamMutation } = useMutation({
        throwOnError: false,
        mutationFn: (id: string) => deleteStream(id),
        onSuccess: async () => {
            createToast({ variant: "success", title: "Success", children: "Stream deleted successfully" });
            await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Deleting stream failed" });
        },
    });

    const performMutation = (stream: AudioStream | Omit<AudioStream, "id">) =>
        activeStream ? updateStreamMutation({ ...activeStream, ...stream }) : createStreamMutation(stream);

    const resolveConfirmation = (confirmed: boolean) => {
        if (confirmed && activeStream) {
            deleteStreamMutation(activeStream.id);
            setActiveStream(null);
        }
    };

    return (
        <>
            <ConfirmationModal open={confirmModalOpen} setOpen={setConfirmModalOpen} onClose={resolveConfirmation} />
            <TableSearchPage
                queryKey={QUERY_KEY}
                queryFn={getStreams}
                heading={"Streams"}
                zeroStateLabel={"Stream"}
                createModal={
                    <SpecificStreamModal
                        setOpen={setModalOpen}
                        open={modalOpen}
                        audioStream={activeStream || undefined}
                        onSave={performMutation}
                    />
                }
                setCreateModalOpen={() => openModal(null)}
                emptyTableLabel={"No Streams"}
                tableConfig={{
                    columns: ["ID", "Name", "URL", ""],
                    renderRow: (stream: AudioStream) => (
                        <StreamTableRow
                            stream={stream}
                            openModal={openModal}
                            promptDelete={() => {
                                setActiveStream(stream);
                                setConfirmModalOpen(true);
                            }}
                        />
                    ),
                }}
            />
        </>
    );
};

export default StreamsPage;
