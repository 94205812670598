import { Button, Center, Group, Loader, Modal, Stack, Textarea, Title } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { exportScuConfig } from "shared/api/bryx911/scusApi";
import { useCopyToClipboard } from "shared/hooks/useClipboard";
import { createToast } from "shared/notifications/createToast";
import { FullScu } from "shared/types/bryx911/Scu";

export type ExportScuConfigModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    scu: FullScu;
};

export const ExportScuConfigModal = ({ setOpen, open, scu }: ExportScuConfigModalProps) => {
    const copy = useCopyToClipboard();

    const { data: config, isLoading } = useQuery({
        throwOnError: false,
        queryKey: [scu.id, "configExport"],
        queryFn: () => exportScuConfig(scu.id),
        enabled: open,
    });

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Title order={1}>{`Export ${scu?.name} Config`}</Title>}
            centered
            size={"xl"}
        >
            {isLoading || !config ? (
                <Center>
                    <Loader color={"red"} size={"lg"} />
                </Center>
            ) : (
                <form>
                    <Stack gap={8}>
                        <Textarea maxRows={12} placeholder={"Loading..."} value={config} readOnly />
                        <Group gap={8}>
                            <Button
                                variant="primary"
                                onClick={() =>
                                    void copy(config).then(() =>
                                        createToast({ variant: "success", title: "Copied", children: "Copied SCU config to clipboard" }),
                                    )
                                }
                            >
                                Copy
                            </Button>
                            <Link to={`data:text/plain,${config}`} download={`${scu.id}.cfg.json`}>
                                <Button>Download</Button>
                            </Link>
                        </Group>
                    </Stack>
                </form>
            )}
            <Group w={"100%"} justify={"flex-end"} mt={16}>
                <Button variant="secondary" leftSection={<IconCheck />} onClick={() => setOpen(false)}>
                    Done
                </Button>
            </Group>
        </Modal>
    );
};
