import {
    ActionIcon,
    Alert,
    AppShell,
    Burger,
    Center,
    Container,
    CopyButton,
    Flex,
    Group,
    Image,
    Menu,
    Stack,
    Text,
    TextInput,
    Title,
} from "@mantine/core";
import {
    IconAlertTriangle,
    IconChevronDown,
    IconCopy,
    IconDatabase,
    IconDeviceMobile,
    IconFileInfo,
    IconHomeCog,
    IconUserCog,
} from "@tabler/icons-react";
import logo from "app/logo.png";
import classes from "app/Navbar.module.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmationModal } from "shared/components/ConfirmationModal";
import { useUserStore } from "shared/stores/userStore";

type NavigationLink = { type: "link"; path: string; name: string };
type NavigationFolder = { type: "folder"; name: string; icon: React.ElementType; children: NavigationLink[] };
type Navigation = (NavigationLink | NavigationFolder)[];

const navigation: Navigation = [
    {
        type: "folder",
        name: "General",
        icon: IconFileInfo,
        children: [
            { type: "link", path: "/dashboard", name: "Dashboard" },
            { type: "link", path: "/agents", name: "Support Agents" },
        ],
    },
    {
        type: "folder",
        name: "Core",
        icon: IconDeviceMobile,
        children: [
            { type: "link", path: "/clients", name: "Clients" },
            { type: "link", path: "/agencies", name: "Agencies" },
            { type: "link", path: "/signups", name: "Signups" },
            { type: "link", path: "/jobs", name: "Jobs" },
            { type: "link", path: "/streams", name: "Streams" },
            { type: "link", path: "/api-keys", name: "API Keys" },
        ],
    },
    {
        type: "folder",
        name: "Station Alerting",
        icon: IconHomeCog,
        children: [
            { type: "link", path: "/scus", name: "SCUs" },
            { type: "link", path: "/boards", name: "Station Boards" },
        ],
    },
    {
        type: "folder",
        name: "RMS",
        icon: IconDatabase,
        children: [{ type: "link", path: "/rms", name: "TBD" }],
    },
];

export default function Navbar() {
    const navigate = useNavigate();
    const loc = useLocation();
    const [apiKey, resetUserStore] = useUserStore((s) => [s.session.apiKey, s.resetStore]);
    const [showModal, setShowModal] = useState<"logout" | "apiKey" | false>(false);
    const [opened, setOpened] = useState(false);

    const logout = () => {
        resetUserStore();
        navigate("/login");
    };

    const onConfirmationModalDismiss = (confirmed: boolean) => showModal == "logout" && confirmed && logout();

    const isActive = (name: string) => loc.pathname.includes(name);
    const isFolderActive = (folder: NavigationFolder) => folder.children.map((child) => isActive(child.path)).some((t) => t);

    const items = navigation.map((link) => {
        if (link.type == "folder") {
            const LinkIcon = link.icon;

            const menuItems = link.children.map((item) => (
                <Menu.Item
                    key={item.path}
                    className={classes.link}
                    onClick={(event) => {
                        navigate(item.path);
                        event.preventDefault();
                    }}
                >
                    <Text fz={"md"} fs={isActive(link.name) ? "bold" : undefined}>
                        {item.name}
                    </Text>
                </Menu.Item>
            ));

            return (
                <Menu
                    key={link.name}
                    classNames={{ dropdown: classes.dropdown }}
                    trigger="click-hover"
                    transitionProps={{ exitDuration: 0 }}
                    withinPortal
                >
                    <Menu.Target>
                        <a className={classes.link} onClick={(event) => event.preventDefault()}>
                            <Center>
                                <LinkIcon style={{ height: "1.25rem", width: "1.25rem" }} />
                                <Text mx={8} fz={"md"} fs={isFolderActive(link) ? "bold" : undefined}>
                                    {link.name}
                                </Text>
                                <IconChevronDown size="0.9rem" stroke={1.5} />
                            </Center>
                        </a>
                    </Menu.Target>
                    <Menu.Dropdown>{menuItems}</Menu.Dropdown>
                </Menu>
            );
        }

        return <></>;
    });

    const actionMenu = (
        <Menu classNames={{ dropdown: classes.dropdown }} trigger={"click-hover"} transitionProps={{ exitDuration: 0 }} withinPortal>
            <Menu.Target>
                <a className={classes.link}>
                    <Center>
                        <IconUserCog />
                        <IconChevronDown size={"0.9rem"} stroke={1.5} />
                    </Center>
                </a>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={() => setShowModal("apiKey")}>API Key</Menu.Item>
                <Menu.Item onClick={() => setShowModal("logout")}>Sign Out</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );

    return (
        <>
            <ConfirmationModal
                open={showModal != false}
                setOpen={() => setShowModal(false)}
                onClose={onConfirmationModalDismiss}
                title={showModal == "logout" ? `Confirm Sign Out` : "API Key"}
                prompt={
                    showModal == "logout" ? (
                        "Are you sure you want to sign out?"
                    ) : (
                        <Stack gap={16}>
                            <Alert color={"orange"} title={<Title order={3}>Use Your Noodle</Title>} icon={<IconAlertTriangle />}>
                                Anyone with access to your API key can perform any support action on your behalf. If you believe that your
                                API key has been compromised, contact an administrator immediately.
                            </Alert>
                            <Group gap={8} w={"100%"}>
                                <TextInput disabled label={"API Key"} value={apiKey} flex={1} />
                                <CopyButton value={apiKey}>
                                    {({ copied, copy }) => (
                                        <ActionIcon size={"lg"} mt={"1.25rem"} disabled={copied} onClick={copy}>
                                            <IconCopy />
                                        </ActionIcon>
                                    )}
                                </CopyButton>
                            </Group>
                        </Stack>
                    )
                }
            />
            <AppShell.Header className={classes.header}>
                <Flex direction={"row"} justify={"space-between"}>
                    <Container ml={"2rem"} size="xl" w={"100vw"} maw={"100vw"}>
                        <div className={classes.inner}>
                            <Image h={36} w={"auto"} mr={36} src={logo} alt="Bryx Inc" onClick={() => "/dashboard"} />
                            <Group visibleFrom="sm" hidden={opened} justify={"space-between"} w={"100%"}>
                                <Group gap={8}>{items}</Group>
                                {actionMenu}
                            </Group>
                        </div>
                    </Container>
                    <Burger mr={32} my={"auto"} opened={opened} onClick={() => setOpened(!opened)} size="sm" hiddenFrom="sm" />
                </Flex>
            </AppShell.Header>
            <AppShell.Navbar className={classes.navbar} hiddenFrom={"sm"} style={!opened ? { display: "none" } : undefined}>
                {items}
                {actionMenu}
            </AppShell.Navbar>
        </>
    );
}
