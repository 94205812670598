import { Notification, NotificationProps, Text } from "@mantine/core";
import { IconCheck, IconInfoSmall, IconX } from "@tabler/icons-react";
import { toast } from "sonner";
import { match } from "ts-pattern";

type CreateToastProps = {
    options?: Parameters<(typeof toast)["custom"]>[1];
    variant: "success" | "error" | "info";
} & Omit<NotificationProps, "variant">;

export const createToast = ({ variant, title, options, children, withCloseButton = true }: CreateToastProps) => {
    const iconProps = match(variant)
        .with("success", () => ({
            icon: <IconCheck size="20" />,
            color: "green.5",
        }))
        .with("error", () => ({
            icon: <IconX size="20" />,
            color: "red.5",
        }))
        .with("info", () => ({
            icon: <IconInfoSmall size="28" />,
            color: "blue.5",
        }))
        .exhaustive();

    return toast.custom(
        (t) => {
            return (
                <Notification
                    withCloseButton={withCloseButton}
                    onClose={() => toast.dismiss(t)}
                    key={t}
                    title={<Text fz="lg">{title}</Text>}
                    {...iconProps}
                >
                    <Text fz="md">{children}</Text>
                </Notification>
            );
        },
        {
            duration: 5000,
            style: { backgroundColor: "transparent" },
            className: "toast-container",
            ...options,
        },
    );
};
