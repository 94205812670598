import { Table } from "@mantine/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Nullable } from "@tsly/maybe";
import { SpecificApiKeyModal } from "pages/apiKeys/SpecificApiKeyModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    addPermissionToApiKey,
    createApiKey,
    deleteApiKey,
    deletePermissionFromApiKey,
    getApiKey,
    getApiKeys,
} from "shared/api/bryx911/apiKeysApi";
import { ActionMenu } from "shared/components/ActionMenu";
import { ConfirmationModal } from "shared/components/ConfirmationModal";
import CopyButton from "shared/components/CopyButton";
import { RightTd } from "shared/components/Tables";
import TableSearchPage from "shared/components/TableSearchPage";
import { createToast } from "shared/notifications/createToast";
import { ApiKey, ApiKeyParameters, ApiKeyPermission, ApiKeyPermissionTypeDescriptions } from "shared/types/bryx911/ApiKeys";

const QUERY_KEY = "searchApiKeys";

const ApiKeyTableRow = ({
    apiKey,
    openModal,
    promptDelete,
}: {
    apiKey: ApiKey;
    openModal: (apiKey: Nullable<ApiKey>) => void;
    promptDelete: (apiKey: ApiKey) => void;
}) => (
    <Table.Tr>
        <Table.Td>
            <CopyButton text={apiKey.id} />
        </Table.Td>
        <Table.Td>{apiKey.name}</Table.Td>
        <Table.Td>{apiKey.permissions.map((p) => ApiKeyPermissionTypeDescriptions[p.type]).join(", ")}</Table.Td>
        <RightTd>
            <ActionMenu
                actions={{
                    "Edit Permissions...": () => openModal(apiKey),
                    Delete: () => promptDelete(apiKey),
                }}
            />
        </RightTd>
    </Table.Tr>
);

const ApiKeysPage = () => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [activeApiKey, setActiveApiKey] = useState<Nullable<ApiKey>>(null);

    const openModal = (apiKey: Nullable<ApiKey>) => {
        setActiveApiKey(apiKey);
        setModalOpen(true);
    };

    useEffect(() => {
        id &&
            getApiKey(id)
                .then((apiKey) => openModal(apiKey))
                .catch(() => createToast({ variant: "error", title: "Error", children: "Unable to open API key" }));
    }, [id]);

    const { mutate: changePermissionMutation } = useMutation({
        throwOnError: false,
        mutationFn: ({ id, permission, action }: { id: string; permission: ApiKeyPermission; action: "add" | "remove" }) =>
            action == "add" ? addPermissionToApiKey(id, permission) : deletePermissionFromApiKey(id, permission),
        onSuccess: async () => {
            setModalOpen(false);
            createToast({ variant: "success", title: "Success", children: "API key updated successfully" });
            await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Updating API key failed" });
        },
    });

    const { mutate: createApiKeyMutation } = useMutation({
        throwOnError: false,
        mutationFn: (params: ApiKeyParameters) => createApiKey(params),
        onSuccess: async () => {
            setModalOpen(false);
            createToast({ variant: "success", title: "Success", children: "API key created successfully" });
            await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Creating API key failed" });
        },
    });

    const { mutate: deleteApiKeyMutation } = useMutation({
        throwOnError: false,
        mutationFn: (id: string) => deleteApiKey(id),
        onSuccess: async () => {
            createToast({ variant: "success", title: "Success", children: "API key deleted successfully" });
            await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Deleting API key failed" });
        },
    });

    const resolveConfirmation = (confirmed: boolean) => {
        if (confirmed && activeApiKey) {
            deleteApiKeyMutation(activeApiKey.id);
            setActiveApiKey(null);
        }
    };

    return (
        <>
            <ConfirmationModal open={confirmModalOpen} setOpen={setConfirmModalOpen} onClose={resolveConfirmation} />
            <TableSearchPage
                queryKey={QUERY_KEY}
                queryFn={getApiKeys}
                heading={"API Keys"}
                zeroStateLabel={"API Key"}
                createModal={
                    <SpecificApiKeyModal
                        setOpen={setModalOpen}
                        open={modalOpen}
                        apiKey={activeApiKey || undefined}
                        onSave={createApiKeyMutation}
                        onChangePermissions={changePermissionMutation}
                    />
                }
                setCreateModalOpen={() => openModal(null)}
                emptyTableLabel={"No API Keys"}
                tableConfig={{
                    columns: ["ID", "Name", "Permissions", ""],
                    renderRow: (apiKey: ApiKey) => (
                        <ApiKeyTableRow
                            apiKey={apiKey}
                            openModal={openModal}
                            promptDelete={() => {
                                setActiveApiKey(apiKey);
                                setConfirmModalOpen(true);
                            }}
                        />
                    ),
                }}
            />
        </>
    );
};

export default ApiKeysPage;
