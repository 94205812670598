import { z } from "zod";

/**
 * Create range of numbers.
 * @param start Start if stop also given, otherwise stop.
 * @param stop
 * @param step Steps between numbers in range.
 * @return Array of numbers in range.
 */
export function range(start: number, stop?: number, step?: number): number[] {
    if (stop == undefined) {
        stop = start;
        start = 0;
    }

    if (step == undefined) {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    const result = [];
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
}

export const selectOptionsFromEnum = (schema: z.EnumLike, toString?: (x: string) => string) => {
    const fixup = toString ?? ((v: string) => `${v.charAt(0).toUpperCase()}${v.slice(1)}`);

    return Object.keys(schema).map((v) => ({
        value: v,
        label: fixup(v),
    }));
};

export const selectOptionsFromMinimalType = <T>(
    obj: T extends { id: string; name: string }[] ? T : never,
    toString?: (x: string) => string,
    nameNotId: boolean = false,
) => {
    const fixup = toString ?? ((v: string) => `${v.charAt(0).toUpperCase()}${v.slice(1)}`);

    return obj.map((v) => ({
        value: nameNotId ? v.name : v.id,
        label: fixup(v.name),
    }));
};

export const camelCaseToTitleCase = (str: string) => str.charAt(0).toUpperCase() + str.replace(/([A-Z])/g, " $1").slice(1);

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.substring(1);
