import { Button, Flex, PasswordInput, TextInput } from "@mantine/core";
import { UseMutateFunction } from "@tanstack/react-query";
import useLoginStore from "app/login/LoginStore";
import { FormEvent } from "react";
import { HttpError } from "shared/types/Http";
import { UserAccount } from "shared/types/UserAccount";

const LoginForm = ({
    loginMutation,
}: {
    loginMutation: UseMutateFunction<
        UserAccount,
        HttpError,
        {
            email: string;
            password: string;
            token: string;
        },
        unknown
    >;
}) => {
    const { email, password, token, setEmail, setPassword, setToken } = useLoginStore(
        ({ email, password, token, hasError, setEmail, setPassword, setToken }) => ({
            email,
            password,
            token,
            hasError,
            setEmail,
            setPassword,
            setToken,
        }),
    );

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        loginMutation({ email, password, token });
    };

    return (
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Flex w={"100%"} direction={"column"} gap={"2rem"}>
                <TextInput id="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <PasswordInput label="Password" placeholder="" value={password} onChange={(e) => setPassword(e.target.value)} />
                <TextInput id="token" label="TOTP Token" value={token} onChange={(e) => setToken(e.target.value)} />
                <Button fullWidth variant="primary" type="submit">
                    Sign In
                </Button>
            </Flex>
        </form>
    );
};

export default LoginForm;
