import { Button, Center, Group, Loader, Modal, Select, Stack, TextInput, Title } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SCU_QUERY_KEY } from "pages/scus/SpecificScu";
import { Dispatch, SetStateAction, useEffect } from "react";
import { editScu } from "shared/api/bryx911/scusApi";
import { createToast } from "shared/notifications/createToast";
import { FullScu, ScuModelEnum } from "shared/types/bryx911/Scu";
import { NonNullablePick } from "shared/types/Utility";
import { valueToEditable } from "shared/utils/diff";
import { scuModelNameString } from "shared/utils/scus";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export type EditBasicInfoModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    scu?: FullScu;
};

type ScuInfoStore = NonNullablePick<FullScu, "name" | "model" | "serial" | "ipAddress" | "email" | "macAddress"> & {
    initialState: FullScu | null;
    dirty: boolean;
    setName: (name: string) => void;
    setIpAddress: (ipAddress: string) => void;
    setEmail: (email: string) => void;
    setMacAddress: (macAddress: string) => void;
    initialize: (scu: FullScu) => void;
};

const useScuInfoStore = create<ScuInfoStore>()(
    devtools(
        immer((set) => ({
            name: "",
            model: "other",
            serial: "",
            ipAddress: "",
            email: "",
            macAddress: "",
            dirty: false,
            initialState: null,
            setName: (name: string) => set((store) => ({ name, dirty: name != store.initialState?.name ?? true })),
            setIpAddress: (ipAddress: string) => set((store) => ({ ipAddress, dirty: ipAddress != store.initialState?.ipAddress ?? true })),
            setEmail: (email: string) => set((store) => ({ email, dirty: email != store.initialState?.email ?? true })),
            setMacAddress: (macAddress: string) =>
                set((store) => ({ macAddress, dirty: macAddress != store.initialState?.macAddress ?? true })),
            initialize: (scu: FullScu) =>
                set({
                    name: scu.name,
                    model: scu.model,
                    serial: scu.serial,
                    ipAddress: scu.ipAddress ?? "",
                    email: scu.email ?? "",
                    macAddress: scu.macAddress ?? "",
                    initialState: scu,
                    dirty: false,
                }),
        })),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "SCU Basic Info" },
    ),
);

export const EditBasicInfoModal = ({ setOpen, open, scu }: EditBasicInfoModalProps) => {
    const queryClient = useQueryClient();

    const { name, model, serial, ipAddress, email, macAddress, dirty, setName, setIpAddress, setEmail, setMacAddress, initialize } =
        useScuInfoStore();

    useEffect(() => scu && initialize(scu), [scu]);

    const { mutate: editScuMutation, isPending } = useMutation({
        throwOnError: false,
        mutationFn: ({
            name,
            model,
            serial,
            ipAddress,
            email,
            macAddress,
        }: {
            name: string;
            model: ScuModelEnum;
            serial: string;
            ipAddress: string;
            email: string;
            macAddress: string;
        }) =>
            editScu(
                scu!.id,
                valueToEditable(name),
                serial != scu?.serial ? valueToEditable(serial) : null,
                valueToEditable(ipAddress),
                valueToEditable(macAddress),
                model != scu?.model ? valueToEditable(model) : null,
                valueToEditable(email),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [SCU_QUERY_KEY, scu?.id] });
            setOpen(false);
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Editing SCU info failed" });
        },
    });

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Title order={1}>{`Edit ${scu?.name} Basic Info`}</Title>}
            centered
            size={"xl"}
        >
            {!scu ? (
                <Center>
                    ßå
                    <Loader color={"red"} size={"lg"} />
                </Center>
            ) : (
                <form>
                    <Stack gap={8}>
                        <Group gap={8}>
                            <TextInput flex={1} label={"Name"} value={name} onChange={(e) => setName(e.target.value)} />
                            <Select
                                id={"model"}
                                label={"Model"}
                                placeholder={"SCU Model..."}
                                value={scuModelNameString(model)}
                                data={[scuModelNameString(model)]}
                                onChange={() => {}}
                                disabled
                            />
                        </Group>
                        <Group gap={8}>
                            <TextInput w={"100%"} label={"Serial"} value={serial} disabled />
                            <TextInput w={"100%"} label={"IP Address"} value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                        </Group>
                        <Group gap={8}>
                            <TextInput w={"100%"} label={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
                            <TextInput
                                disabled={!!macAddress}
                                w={"100%"}
                                label={"MAC Address"}
                                value={macAddress}
                                onChange={(e) => setMacAddress(e.target.value)}
                            />
                        </Group>
                    </Stack>
                </form>
            )}
            <Group w={"100%"} justify={"flex-end"}>
                {isPending ? (
                    <Center>
                        <Loader color={"red"} />
                    </Center>
                ) : (
                    <Group gap={8} mt={16}>
                        <Button variant="secondary" leftSection={<IconX />} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!dirty}
                            variant="primary"
                            leftSection={<IconCheck />}
                            onClick={() => editScuMutation({ name, model, serial, ipAddress, email, macAddress })}
                        >
                            Save
                        </Button>
                    </Group>
                )}
            </Group>
        </Modal>
    );
};
