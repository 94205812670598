import { Chip, Text } from "@mantine/core";
import { IconBan, IconCheck, IconDisabled, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { getClients } from "shared/api/bryx911/clientsApi";
import CardSearchPage from "shared/components/CardSearchPage";
import { SearchResultCard } from "shared/components/SearchResultCard";
import { ShortClientAgency } from "shared/types/bryx911/Clients";
import { match } from "ts-pattern";

const SearchClients = () => {
    const navigate = useNavigate();
    const cadDept = (agencyKey: string) => agencyKey.split(".").reverse()[0].toUpperCase();

    const agenciesElements = (agencies: ShortClientAgency[]) => (
        <Text variant="body-small">
            {agencies.length == 0 ? (
                "No Agencies"
            ) : (
                <>
                    {agencies
                        .sort((a, b) => (a.isManager === b.isManager ? 0 : a.isManager ? -1 : 1))
                        .map((agency, index) => (
                            <>
                                <span key={agency.id} style={agency.isManager ? { fontWeight: "bold" } : undefined}>
                                    {cadDept(agency.agencyKey)}
                                </span>
                                {index < agencies.length - 1 ? ", " : ""}
                            </>
                        ))}
                </>
            )}
        </Text>
    );

    return (
        <CardSearchPage
            queryKey={"searchClients"}
            queryFn={getClients}
            heading={"Clients"}
            zeroStateLabel={"client"}
            createModal={<div />}
            setCreateModalOpen={() => {
                /* no op */
            }}
            cardContentFn={(v) => (
                <SearchResultCard
                    key={v.id}
                    heading={v.commonName}
                    subheading={v.email}
                    content={agenciesElements(v.agencies)}
                    onClick={() => navigate(`/clients/${v.id}`)}
                    adornment={match(v.status)
                        .with("active", () => (
                            <Chip defaultChecked radius={"sm"} color={"green.5"} icon={<IconCheck size={16} />}>
                                Active
                            </Chip>
                        ))
                        .with("disabled", () => (
                            <Chip defaultChecked radius={"sm"} color={"yellow"} icon={<IconDisabled size={16} />}>
                                Disabled
                            </Chip>
                        ))
                        .with("banned", () => (
                            <Chip defaultChecked radius={"sm"} color={"red.4"} icon={<IconBan size={16} />}>
                                Banned
                            </Chip>
                        ))
                        .with("deleted", () => (
                            <Chip defaultChecked radius={"sm"} color={"red.4"} icon={<IconTrash size={16} />}>
                                Deleted
                            </Chip>
                        ))
                        .exhaustive()}
                />
            )}
        />
    );
};

export default SearchClients;
