import { useEffect, useState } from "react";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type UserStore = {
    session: {
        apiKey: string;
        deviceId: string;
    };
    colorMode: "light" | "dark" | "auto";
    setSession: (args: { apiKey: string; deviceId: string }) => void;
    resetStore: () => void;
};

const userStoreDefault = {
    session: {
        apiKey: "",
        deviceId: "",
    },
    colorMode: "auto" as "light" | "dark" | "auto",
};

export const useUserStore = create<UserStore>()(
    devtools(
        // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#options
        persist(
            immer((set) => {
                const resetStore = () => {
                    set(userStoreDefault);
                    useUserStore.persist.clearStorage();
                };
                return {
                    ...userStoreDefault,
                    setSession: (session) => set({ session }),
                    resetStore,
                };
            }),
            {
                name: "tools.store.user",
                storage: createJSONStorage(() => localStorage),
                partialize: (store) => ({
                    session: store.session,
                }),
            },
        ),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "User" },
    ),
);

export const useIsSessionValid = () => {
    const apiKey = useUserStore((s) => s.session.apiKey);
    const [isSessionValid, setIsSessionValid] = useState<boolean>(Boolean(apiKey.replace(/\s+/g, "")));

    useEffect(() => {
        setIsSessionValid(Boolean(apiKey.replace(/\s+/g, "")));
    }, [apiKey]);

    return isSessionValid;
};
