import { Chip, ChipProps, Group, Text } from "@mantine/core";

export const AdornmentTag = ({ text, ...rest }: { text: string } & Omit<ChipProps, "children">) => (
    <Chip {...rest} defaultChecked disabled radius={"sm"} style={{ width: "fit-content", maxWidth: "unset" }}>
        <Text c={"white"}>{text}</Text>
    </Chip>
);

export const DualAdornmentTag = ({
    primaryText,
    secondaryText,
    ...rest
}: { primaryText: string; secondaryText: string } & Omit<ChipProps, "children">) => (
    <Chip {...rest} defaultChecked disabled radius={"sm"} style={{ width: "fit-content", maxWidth: "unset" }}>
        <Group ml={8} gap={8}>
            <Text fw={"bold"} c={"white"}>
                {primaryText}
            </Text>
            <Text c={"white"}>{secondaryText}</Text>
        </Group>
    </Chip>
);
