import { ActionIcon, Menu } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import { obj } from "@tsly/obj";

type ActionMenuProps = {
    actions: { [label: string]: { fn: () => void; when: boolean } | (() => void) };
    trigger?: React.ReactNode;
};

export const ActionMenu = (props: ActionMenuProps) => {
    return (
        <Menu>
            <Menu.Target>
                {props.trigger || (
                    <ActionIcon variant="subtle" size="lg" aria-label="More actions">
                        <IconDotsVertical size="16" />
                    </ActionIcon>
                )}
            </Menu.Target>
            <Menu.Dropdown>
                {obj(props.actions).entries.map(([label, def], i) =>
                    typeof def == "function" || def.when ? (
                        <Menu.Item key={i} onClick={() => (typeof def == "function" ? def() : def.fn())}>
                            {label}
                        </Menu.Item>
                    ) : null,
                )}
            </Menu.Dropdown>
        </Menu>
    );
};
