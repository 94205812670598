import { Alert, Button, Center, Group, Loader, Modal, Stack, TextInput, Title } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SCU_QUERY_KEY } from "pages/scus/SpecificScu";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { resetScu } from "shared/api/bryx911/scusApi";
import { createToast } from "shared/notifications/createToast";
import { FullScu } from "shared/types/bryx911/Scu";

export type ResetScuModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    scu?: FullScu;
};

export const ResetScuModal = ({ setOpen, open, scu }: ResetScuModalProps) => {
    const queryClient = useQueryClient();

    const [confirmedSerial, setConfirmedSerial] = useState("");

    useEffect(() => {
        // reset password field to blank when closed.
        // do it when closed to prevent any 'flicker' from a rerender on open
        !open && setConfirmedSerial("");
    }, [open]);

    const { mutate: resetScuMutation, isPending } = useMutation({
        throwOnError: false,
        mutationFn: () => resetScu(scu!.id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [SCU_QUERY_KEY, scu?.id] });
            setOpen(false);
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Resetting SCU failed" });
        },
    });

    return (
        <Modal opened={open} onClose={() => setOpen(false)} title={<Title order={1}>{`Reset ${scu?.name}`}</Title>} centered size={"xl"}>
            {!scu ? (
                <Center>
                    <Loader color={"red"} size={"lg"} />
                </Center>
            ) : (
                <Stack gap={8}>
                    <Alert title={<Title order={3}>Are you sure?</Title>} variant={"outlined"}>
                        <li>
                            &apos;{scu.name}&apos; ({scu.serial}) will be factory reset.{" "}
                        </li>
                        <li>
                            <b>This action cannot be undone.</b>
                        </li>
                    </Alert>
                    <TextInput label={"Confirm SCU Serial"} value={confirmedSerial} onChange={(e) => setConfirmedSerial(e.target.value)} />
                </Stack>
            )}
            <Group w={"100%"} justify={"flex-end"} gap={8} mt={16}>
                {isPending ? (
                    <Center>
                        <Loader color={"red"} />
                    </Center>
                ) : (
                    <>
                        <Button variant="secondary" leftSection={<IconX />} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" leftSection={<IconCheck />} onClick={() => resetScuMutation()}>
                            Confirm
                        </Button>
                    </>
                )}
            </Group>
        </Modal>
    );
};
