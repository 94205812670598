import { Group, Title } from "@mantine/core";
import { lazy } from "react";
import { getAgency } from "shared/api/bryx911/agenciesApi";
import TabbedEntityPage from "shared/components/TabbedEntityPage";
import { LongAgency } from "shared/types/bryx911/Agencies";

const SpecificAgency = ({ id, tab }: { id: string; tab?: string }) => (
    <TabbedEntityPage
        id={id}
        tab={tab}
        slug={"agencies"}
        fetchFn={getAgency}
        queryKey={"getAgency"}
        headingTextFn={(agency: LongAgency) => (
            <Group gap={16}>
                <Title order={1}>{agency.name}</Title>
                <Title order={5} mt={12} c={"gray"}>
                    {agency.agencyKey}
                </Title>
            </Group>
        )}
        tabs={[
            {
                slug: "units",
                name: "Units",
                component: lazy(() => import("./specific/UnitsTab")),
                countFn: (agency) => agency.units.length,
            },
            {
                slug: "clients",
                name: "Clients",
                component: lazy(() => import("./specific/ClientsTab")),
                countFn: (agency) => agency.clientStats.truckCount + agency.clientStats.userCount,
            },
            {
                slug: "groups",
                name: "Groups",
                component: lazy(() => import("./specific/GroupsTab")),
                countFn: (agency) => agency.groupStats.dispatchCount + agency.groupStats.messagingCount,
            },
            { slug: "scus", name: "SCUs", component: lazy(() => import("./specific/ScusTab")), countFn: (agency) => agency.scuCount },
            { slug: "actions", name: "Actions", component: lazy(() => import("./specific/ActionsTab")) },
            { slug: "translations", name: "Translations", component: lazy(() => import("./specific/TranslationsTab")) },
            { slug: "features", name: "Features", component: lazy(() => import("./specific/FeaturesTab")) },
            {
                slug: "streams",
                name: "Streams",
                component: lazy(() => import("./specific/StreamsTab")),
                countFn: (agency) => agency.streams.length,
            },
            {
                slug: "stations",
                name: "Stations",
                component: lazy(() => import("./specific/StationsTab")),
                countFn: (agency) => agency.stations.length,
            },
            { slug: "site-surveys", name: "Site Surveys", component: lazy(() => import("./specific/SiteSurveysTab")) },
            { slug: "raws", name: "Raws", component: lazy(() => import("./specific/RawsTab")) },
            { slug: "parsers", name: "Parsers", component: lazy(() => import("./specific/ParsersTab")) },
            { slug: "locations", name: "Locations", component: lazy(() => import("./specific/LocationsTab")) },
        ]}
    />
);

export default SpecificAgency;
