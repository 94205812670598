import { ActionIcon, Button, Center, Divider, Group, Modal, Select, Stack, Text, TextInput, Title } from "@mantine/core";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import { obj } from "@tsly/obj";
import { Dispatch, SetStateAction, useEffect } from "react";
import { getAgencies } from "shared/api/bryx911/agenciesApi";
import { AddButton } from "shared/components/AddButton";
import SearchWithResults from "shared/components/SearchWithResults";
import {
    ApiKey,
    ApiKeyOwnerType,
    ApiKeyPermission,
    ApiKeyPermissionTypeDescriptions,
    ApiKeyPermissionTypes,
    ApiKeyPermissionTypesSchema,
} from "shared/types/bryx911/ApiKeys";
import { selectOptionsFromEnum } from "shared/utils/functions";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export type SpecificApiKeyModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    apiKey?: ApiKey;
    onChangePermissions: (args: { id: string; permission: ApiKeyPermission; action: "add" | "remove" }) => void;
    onSave: (s: ApiKey | Omit<ApiKey, "id">) => void;
};

type ApiKeyStoreState = Omit<ApiKey, "id" | "owner"> & {
    ownerType: ApiKeyOwnerType;
    ownerId: string;
    ownerAgencyName: string;
    setName: (s: string) => void;
    setKey: (s: string) => void;
    setOwnerType: (t: ApiKeyOwnerType) => void;
    setOwnerId: (s: string) => void;
    setOwnerAgencyName: (s: string) => void;
    addPermission: (p: ApiKeyPermission) => void;
    setPermissionAtIndex: (p: ApiKeyPermissionTypes, i: number) => void;
    removePermission: (p: ApiKeyPermission) => void;
    reset: (k?: ApiKey) => void;
};

const defaults: Omit<ApiKey, "id" | "owner"> & { ownerType: ApiKeyOwnerType; ownerId: string; ownerAgencyName: string } = {
    name: "",
    key: "",
    ownerType: "agency",
    ownerId: "",
    ownerAgencyName: "",
    permissions: [],
};

const useApiKeyStore = create<ApiKeyStoreState>()(
    devtools(
        immer((set) => ({
            ...defaults,
            setName: (name) => set({ name }),
            setKey: (key) => set({ key }),
            setOwnerType: (ownerType) => set({ ownerType }),
            setOwnerId: (ownerId) => set({ ownerId }),
            addPermission: (permission) =>
                set(({ permissions }) => ({
                    permissions: [...permissions, permission],
                })),
            setPermissionAtIndex: (p: ApiKeyPermissionTypes, i: number) =>
                set(({ permissions }) => ({
                    permissions: permissions.map((pp, ii) => (ii == i ? { type: p } : pp)),
                })),
            removePermission: (permission) =>
                set(({ permissions }) => ({
                    permissions: permissions.filter((haystack) => haystack.type == permission.type),
                })),
            setOwnerAgencyName: (ownerAgencyName: string) => set({ ownerAgencyName }),
            reset: (apiKey) =>
                set({
                    ...defaults,
                    ...(apiKey ? obj(apiKey).dropKeys(["owner"]).take() : {}),
                    ...(apiKey
                        ? {
                              ownerType: apiKey.owner.type,
                              ownerId: apiKey.owner.type == "agency" ? apiKey.owner.agencyId : apiKey.owner.name,
                          }
                        : {}),
                }),
        })),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "API Keys" },
    ),
);

export const SpecificApiKeyModal = ({ setOpen, open, apiKey, onSave }: SpecificApiKeyModalProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        name,
        key,
        ownerType,
        ownerId,
        ownerAgencyName,
        permissions,
        setName,
        setKey,
        setOwnerAgencyName,
        setOwnerType,
        setOwnerId,
        addPermission,
        setPermissionAtIndex,
        removePermission,
        reset,
    } = useApiKeyStore();

    useEffect(() => {
        reset(apiKey);

        if (apiKey == null) {
            setKey(uuidv4());
        }
    }, [apiKey]);

    const ownerTypeSelectOptions = [
        { value: "agency", label: "Agency" },
        { value: "other", label: "Other" },
    ];

    const permissionSelectOptions = selectOptionsFromEnum(ApiKeyPermissionTypesSchema.enum).map((option) => ({
        value: option.value,
        label: ApiKeyPermissionTypeDescriptions[option.value as ApiKeyPermissionTypes],
    }));

    const appliedPermissions = permissions.map((p, idx) => (
        <Group key={`permission-${idx}`} gap={8} w={"100%"}>
            <Select
                w={"50%"}
                label={"Permission Type"}
                value={p.type}
                data={permissionSelectOptions}
                onChange={(val) => val && setPermissionAtIndex(val as ApiKeyPermissionTypes, idx)}
            />
            <ActionIcon mt={20} size={"lg"} onChange={() => removePermission(p)}>
                <IconTrash />
            </ActionIcon>
        </Group>
    ));

    return (
        <Modal
            opened={open}
            onClose={() => {
                setOpen(false);
                reset(undefined);
            }}
            title={<Title order={1}>{`${apiKey ? "Edit" : "Create"} API Key`}</Title>}
            centered
            size={"xl"}
        >
            <Stack gap={16}>
                <Title order={3}>Information</Title>
                <TextInput label="Name" placeholder="Service name..." value={name} onChange={(e) => setName(e.target.value)} />
                <TextInput label="Key" placeholder="API key..." value={key} disabled />
                <Stack gap={16}>
                    <Title order={3}>Owner</Title>
                    <Group gap={16}>
                        <Select
                            id={"ownerTypes"}
                            label={"Owner Type"}
                            placeholder={"Owner Type"}
                            value={ownerType}
                            data={ownerTypeSelectOptions}
                            onChange={(val) => {
                                setOwnerType((val as ApiKeyOwnerType) || "agency");
                            }}
                        />
                        {ownerType == "agency" ? (
                            ownerId == "" ? (
                                <SearchWithResults
                                    label={"Agency"}
                                    placeholder={"Search Agencies..."}
                                    w={"100%"}
                                    queryKey={"agencies"}
                                    queryFn={getAgencies}
                                    getDisplayValueForResult={(result) => (
                                        <Text
                                            onClick={() => {
                                                setOwnerId(result.id);
                                                setOwnerAgencyName(result.name);
                                            }}
                                        >
                                            <b>{result.name}</b> ({result.agencyKey})
                                        </Text>
                                    )}
                                />
                            ) : (
                                <Group gap={16} w={"100%"}>
                                    <Text>{ownerAgencyName}</Text>
                                    <ActionIcon
                                        size={"sm"}
                                        onChange={() => {
                                            setOwnerId("");
                                            setOwnerAgencyName("");
                                        }}
                                    >
                                        <IconTrash />
                                    </ActionIcon>
                                </Group>
                            )
                        ) : (
                            <TextInput
                                id={"ownerId"}
                                w={"100%"}
                                label={"Owner Name"}
                                placeholder={"Owner Name"}
                                value={ownerId}
                                onChange={(e) => setOwnerId(e.target.value)}
                            />
                        )}
                    </Group>
                </Stack>
                <Divider m={16} />
                <Stack gap={16}>
                    <Group justify={"space-between"}>
                        <Title order={3}>Permissions</Title>
                        <AddButton onClick={() => addPermission({ type: permissionSelectOptions[0].value as ApiKeyPermissionTypes })} />
                    </Group>
                    {appliedPermissions.length > 0 ? (
                        appliedPermissions
                    ) : (
                        <Center>
                            <Stack gap={16}>
                                <Title order={4}>No Permissions (Unlimited)</Title>
                            </Stack>
                        </Center>
                    )}
                </Stack>
            </Stack>
            <Group mt={16} w={"100%"} justify={"flex-end"}>
                <Group gap={8} mt={16}>
                    <Button
                        variant="primary"
                        leftSection={<IconX />}
                        onClick={() => {
                            setOpen(false);
                            reset(undefined);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="secondary"
                        leftSection={<IconCheck />}
                        onClick={() =>
                            onSave({
                                ...apiKey,
                                name,
                                key,
                                owner: ownerType == "agency" ? { type: ownerType, agencyId: ownerId } : { type: ownerType, name: ownerId },
                                permissions,
                            })
                        }
                    >
                        Save
                    </Button>
                </Group>
            </Group>
        </Modal>
    );
};
