import { AppShell, Box, Center, Container, Flex, Paper, Text } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { ColorModeProvider } from "app/ColorModeProvider";
import classes from "app/login/Login.module.css";
import LoginError from "app/login/LoginError";
import LoginForm from "app/login/LoginForm";
import { LoginLogo } from "app/login/LoginLogo";
import useLoginStore from "app/login/LoginStore";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { signIn } from "shared/api/authApi";
import { useColorMode } from "shared/hooks/useColorMode";
import { useIsSessionValid, useUserStore } from "shared/stores/userStore";
import { HttpError } from "shared/types/Http";
import { v4 as uuid4 } from "uuid";
import { z } from "zod";

const LoginPage = () => {
    const isSessionValid = useIsSessionValid();
    const colorScheme = useColorMode();
    const { hasError } = useLoginStore(({ hasError }) => ({ hasError }));

    if (isSessionValid) {
        return <Navigate to={"/dashboard"} />;
    }

    const { setSession } = useUserStore.getState();

    const { setError, clearForm } = useLoginStore(({ setError, clearForm }) => ({
        setError,
        clearForm,
    }));

    const navigate = useNavigate();
    const location = useLocation();

    const { mutate: loginMutation } = useMutation({
        throwOnError: false,
        mutationFn: ({ email, password, token }: { email: string; password: string; token: string }) => signIn(email, password, token),
        onSuccess: (data) => {
            clearForm();
            setSession({
                apiKey: data.apiKey,
                deviceId: uuid4(),
            });
            navigate(location.state ? z.string().parse(location.state) : "/dashboard");
        },
        onError: (error: HttpError) => {
            setError(error);
        },
    });

    return (
        <ColorModeProvider>
            <AppShell>
                <AppShell.Main bg="var(--page-bg)">
                    <Center w={"100vw"} h={"100vh"}>
                        <Container size={420} w={"32rem"}>
                            <div className={classes.wrapper}>
                                <Paper className={classes.form} p={"2rem"}>
                                    <Flex gap={"2rem"} direction={"column"}>
                                        <Box h={"4rem"} w={"12rem"} mx={"auto"}>
                                            <LoginLogo darkMode={colorScheme == "dark"} />
                                        </Box>
                                        {hasError ? (
                                            <LoginError />
                                        ) : (
                                            <Flex direction={"column"} ta={"center"} gap={"1rem"}>
                                                <Text size={"xl"}>Welcome back!</Text>
                                                <Text size={"md"}>Please enter your information below.</Text>
                                            </Flex>
                                        )}
                                        <LoginForm loginMutation={loginMutation} />
                                    </Flex>
                                </Paper>
                            </div>
                        </Container>
                    </Center>
                </AppShell.Main>
            </AppShell>
        </ColorModeProvider>
    );
};

export default LoginPage;
