import ErrorPage from "app/error/ErrorPage";
import NotFoundPage from "app/error/NotFoundPage";
import { Navigate, useRouteError } from "react-router-dom";
import { useUserStore } from "shared/stores/userStore";
import HttpStatusCode, { HttpError } from "shared/types/Http";
import { match } from "ts-pattern";

export const ErrorBoundary = () => {
    const error = useRouteError() as HttpError;
    const { resetStore } = useUserStore.getState();

    return match(error.status)
        .with(HttpStatusCode.UNAUTHORIZED, () => {
            resetStore();
            return <Navigate to="/login" state={{ from: location.pathname }} />;
        })
        .with(HttpStatusCode.NOT_FOUND, () => <NotFoundPage />)
        .otherwise(() => <ErrorPage error={error} />);
};
