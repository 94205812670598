import { AppShell, Container } from "@mantine/core";
import { pipe } from "@tsly/core";
import { ColorModeProvider } from "app/ColorModeProvider";
import { ErrorBoundary } from "app/ErrorBoundary";
import LoginPage from "app/login/Login";
import Navbar from "app/Navbar";
import AgenciesPage from "pages/agencies/AgenciesPage";
import ApiKeysPage from "pages/apiKeys/ApiKeysPage";
import StationBoardsPage from "pages/boards/StationBoardsPage";
import ClientsPage from "pages/clients/ClientsPage";
import DashboardPage from "pages/dashboard/DashboardPage";
import ScusPage from "pages/scus/ScusPage";
import StreamsPage from "pages/streams/StreamsPage";
import { Suspense } from "react";
import { createPortal } from "react-dom";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import { PageSpinner } from "shared/components/PageSpinner";
import { ToastContainer } from "shared/notifications/ToastContainer";
import { useIsSessionValid } from "shared/stores/userStore";

const AppContainer = () => {
    const hasValidSession = useIsSessionValid();

    if (!hasValidSession) return <Navigate replace to={"/login"} />;

    return (
        <ColorModeProvider>
            {createPortal(<ToastContainer />, document.getElementsByTagName("body")[0])}
            <AppShell header={{ height: "4rem" }}>
                <AppShell.Main bg="var(--page-bg)" style={{ height: "fit-content", overflowY: "scroll", maxHeight: "100vh" }}>
                    <Container mb={"1rem"} bg={"var(--header-bg)"} fluid mah={"16rem"} style={{ zIndex: 10 }}>
                        <Navbar />
                    </Container>
                    <Suspense fallback={<PageSpinner />}>
                        <Outlet />
                    </Suspense>
                </AppShell.Main>
            </AppShell>
        </ColorModeProvider>
    );
};

export const router = pipe(
    <Route errorElement={<ErrorBoundary />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<AppContainer />} errorElement={<ErrorBoundary />}>
            <Route index element={<Navigate replace to="/dashboard" />} />
            <Route path="clients/:id?/:tab?" element={<ClientsPage />} />
            <Route path="agencies/:id?/:tab?" element={<AgenciesPage />} />
            <Route path="streams/:id?" element={<StreamsPage />} />
            <Route path="scus/:id?/:tab?" element={<ScusPage />} />
            <Route path="boards/:id?/:tab?" element={<StationBoardsPage />} />
            <Route path="api-keys/:id?" element={<ApiKeysPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
        </Route>
    </Route>,
    createRoutesFromElements,
    createBrowserRouter,
);
