import { Button, Center, Group, Loader, Modal, Stack, Textarea, Title } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SCU_QUERY_KEY } from "pages/scus/SpecificScu";
import { Dispatch, SetStateAction, useState } from "react";
import { importScuConfig } from "shared/api/bryx911/scusApi";
import { createToast } from "shared/notifications/createToast";
import { FullScu } from "shared/types/bryx911/Scu";

export type ImportScuConfigModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    scu: FullScu;
};

export const ImportScuConfigModal = ({ setOpen, open, scu }: ImportScuConfigModalProps) => {
    const queryClient = useQueryClient();
    const [configString, setConfigString] = useState("");

    const { mutate: importConfigMutation, isPending } = useMutation({
        throwOnError: false,
        mutationFn: ({ scuId, configString }: { scuId: string; configString: string }) => importScuConfig(scuId, configString),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [SCU_QUERY_KEY, scu?.id] });
            createToast({ variant: "success", title: "Success", children: "Successfully imported SCU config" });
            setOpen(false);
        },
        onError: () => {
            createToast({ variant: "error", title: "Error", children: "Importing config failed" });
        },
    });

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(false)}
            title={<Title order={1}>{`Export ${scu?.name} Config`}</Title>}
            centered
            size={"xl"}
        >
            {isPending || !scu ? (
                <Center>
                    <Loader color={"red"} size={"lg"} />
                </Center>
            ) : (
                <form>
                    <Stack gap={12}>
                        <Stack gap={8} ta={"center"}>
                            <Title order={3}>{`'${scu.name}' (${scu.serial})`} will be overwritten.</Title>
                            <Title order={4}>This action cannot be undone.</Title>
                        </Stack>
                        <Textarea
                            placeholder={"SCU configuration JSON..."}
                            value={configString}
                            onChange={(e) => setConfigString(e.target.value)}
                        />
                        <Button variant="primary" onClick={() => alert("todo, just paste it ya dingus")}>
                            Upload File...
                        </Button>
                    </Stack>
                </form>
            )}
            <Group w={"100%"} justify={"flex-end"} mt={16}>
                <Button variant="secondary" leftSection={<IconX />} onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    disabled={!configString}
                    variant="primary"
                    leftSection={<IconCheck />}
                    onClick={() => importConfigMutation({ scuId: scu.id, configString })}
                >
                    Import
                </Button>
            </Group>
        </Modal>
    );
};
