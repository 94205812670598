import { SVGProps } from "react";

export const LoginLogo = ({ darkMode, ...rest }: SVGProps<SVGSVGElement> & { darkMode?: boolean }) => (
    <svg {...rest} viewBox="0 0 160 56" fill="none" xmlns="http://www.w3.org/2000/svg" id={"loginLogo"}>
        <path
            fill="none"
            stroke={darkMode ? "#fff9f9" : "#8f0808"}
            strokeWidth="2px"
            d="m52,1H4c-1.66,0-3,1.34-3,3v48c0,1.66,1.34,3,3,3h48c1.66,0,3-1.34,3-3V4c0-1.66-1.34-3-3-3Z"
        />
        <path
            fill={darkMode ? "#fff9f9" : "#8f0808"}
            d="m63.75,15.45h8.5c3.68,0,6.46.53,8.34,1.6s2.81,2.91,2.81,5.53c0,1.09-.2,2.08-.59,2.97-.38.87-.93,1.6-1.66,2.17-.73.56-1.63.94-2.7,1.13v.2c1.11.2,2.09.54,2.95,1.04.87.49,1.56,1.2,2.05,2.13.51.92.76,2.12.76,3.59,0,1.74-.42,3.23-1.25,4.45-.82,1.22-2,2.15-3.54,2.79-1.52.64-3.33.96-5.43.96h-10.25V15.45Zm4.69,11.78h4.49c2.12,0,3.59-.35,4.41-1.04.82-.69,1.23-1.7,1.23-3.03s-.49-2.33-1.46-2.93c-.96-.6-2.5-.9-4.61-.9h-4.06v7.89Zm0,3.79v9.06h4.94c2.19,0,3.72-.42,4.61-1.27.89-.85,1.33-1.99,1.33-3.44,0-.89-.2-1.65-.61-2.3-.39-.65-1.04-1.15-1.93-1.5-.9-.36-2.11-.55-3.63-.55h-4.71Zm29.71-15.57c2.46,0,4.49.3,6.09.9,1.61.6,2.81,1.51,3.59,2.73.79,1.22,1.19,2.78,1.19,4.67,0,1.41-.26,2.6-.78,3.59-.52.99-1.2,1.81-2.05,2.46-.85.65-1.75,1.17-2.71,1.56l8.11,12.64h-5.29l-6.89-11.45h-4.59v11.45h-4.69V15.45h8.03Zm-.31,3.91h-3.03v9.34h3.24c2.17,0,3.75-.4,4.73-1.21.99-.81,1.49-2,1.49-3.57,0-1.65-.53-2.83-1.58-3.52-1.04-.69-2.66-1.04-4.84-1.04Zm25.92,9.12l6.78-13.03h5.06l-9.49,17.46v11.09h-4.67v-10.92l-9.51-17.64h5.1l6.74,13.03Zm36.66,15.53h-5.33l-7.19-11.7-7.23,11.7h-5.02l9.49-14.8-8.85-13.75h5.18l6.64,10.82,6.6-10.82h5.02l-8.89,13.89"
        />
        <g>
            <path
                fill={darkMode ? "#fff9f9" : "#8f0808"}
                d="m28.04,4.78c.3.26.59.43.78.68,1.56,2.15,3.2,4.25,4.63,6.49,1.45,2.24,2.68,4.62,4.03,6.93.14.24.36.48.6.61,4.69,2.43,9.05,5.37,13.2,8.63.64.51.66.92.02,1.44-4.13,3.33-8.51,6.32-13.24,8.75-.3.15-.6.43-.76.73-2.4,4.69-5.28,9.06-8.49,13.22-.56.73-.95.73-1.53,0-3.29-4.19-6.24-8.6-8.64-13.37-.14-.27-.39-.54-.67-.68-4.7-2.45-9.08-5.39-13.23-8.68-.54-.42-.53-.91,0-1.34,4.15-3.35,8.54-6.33,13.28-8.78.32-.16.62-.46.78-.78,2.4-4.68,5.28-9.06,8.5-13.21.17-.22.42-.38.71-.62h0Zm11.15,30.03c.09-.04.15-.05.21-.08,2.64-1.74,5.29-3.48,7.91-5.24.55-.37.52-.89-.03-1.28-1.43-1-2.86-2.02-4.32-2.97-3.33-2.17-6.76-4.16-10.54-5.47-2.16-.75-4.37-1.15-6.64-.57-.74.19-1.46.48-2.26.74.51-.98.98-1.92,1.51-2.82.12-.21.41-.39.65-.44,1.7-.36,3.42-.37,5.1.07,1.13.3,2.22.75,3.33,1.14-.01-.04-.03-.14-.08-.22-1.77-2.75-3.53-5.5-5.32-8.23-.36-.56-.89-.54-1.28,0-.82,1.17-1.65,2.35-2.43,3.55-2.23,3.43-4.31,6.95-5.74,10.81-.96,2.59-1.44,5.22-.67,7.95.14.49.27.98.43,1.59-.98-.53-1.84-.98-2.68-1.48-.18-.11-.32-.38-.36-.6-.34-1.74-.37-3.49.08-5.2.3-1.13.74-2.23,1.13-3.35-.13.07-.3.14-.45.24-2.6,1.71-5.2,3.41-7.79,5.14-.72.48-.71.98,0,1.47,1.87,1.29,3.74,2.6,5.65,3.82,3.01,1.92,6.14,3.63,9.56,4.73,1.92.62,3.88.95,5.89.47.84-.2,1.65-.51,2.53-.78-.47.91-.96,1.77-1.38,2.66-.2.42-.49.56-.92.64-2.62.49-5.11-.01-7.57-.87-.17-.06-.34-.12-.61-.21.13.25.18.37.26.49,1.65,2.55,3.3,5.11,4.97,7.65.48.72.98.71,1.46-.02,1.26-1.87,2.55-3.72,3.73-5.65,1.84-2.99,3.53-6.07,4.62-9.43.67-2.03,1.08-4.1.56-6.24-.2-.84-.51-1.64-.78-2.52.88.47,1.71.94,2.57,1.36.44.22.65.53.72,1,.28,1.69.25,3.38-.16,5.05-.25,1.02-.56,2.04-.85,3.1h0Z"
            />
            <path
                fill={darkMode ? "#fff9f9" : "#8f0808"}
                d="m27.89,35.36c-3.37.02-6.11-2.7-6.14-6.07-.02-3.32,2.72-6.1,6.04-6.12,3.42-.02,6.17,2.69,6.18,6.07.01,3.36-2.7,6.09-6.09,6.11Z"
            />
        </g>
    </svg>
);
