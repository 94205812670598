import { Button, Flex, Text, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import ErrorWrapper from "app/error/ErrorWrapper";
import { useNavigate } from "react-router-dom";
import { useColorMode } from "shared/hooks/useColorMode";

const icon = (colorMode: "light" | "dark") => (
    <svg fill={"none"} width="326" height="264" viewBox="0 0 326 264" xmlns="http://www.w3.org/2000/svg">
        <ellipse
            fill={colorMode == "light" ? "#ebebeb" : "#332e2e"}
            cx="161.998"
            cy="140.173"
            rx="145"
            ry="73.26"
            transform="rotate(-22.5 161.998 140.173)"
        />
        <path
            fill={"none"}
            stroke={colorMode == "light" ? "#878787" : "#8f8f8f"}
            d="M28.4976 74.8751C20.8663 93.2986 28.6986 116.226 47.8274 138.47C66.9355 160.688 97.2182 182.095 134.154 197.394C171.09 212.694 207.64 218.97 236.862 216.77C266.117 214.568 287.867 203.894 295.499 185.471C303.13 167.047 295.298 144.119 276.169 121.876C257.061 99.6573 226.778 78.2507 189.842 62.9514C152.906 47.652 116.356 41.3756 87.1339 43.5753C57.8796 45.7774 36.1289 56.4515 28.4976 74.8751Z"
        />
        <path
            fill={colorMode == "light" ? "#fcfcfc" : "#150303"}
            stroke={colorMode == "light" ? "#8f0808" : "#ff6565"}
            d="M198.938 122.233L182.967 149.895L155.305 133.925L153.573 132.925L152.573 134.657L136.603 162.318L108.941 146.348L106.944 145.195L106.085 147.335L97.1446 169.608L72.563 77.868C70.5673 70.4199 74.9874 62.7641 82.4355 60.7684L139.862 45.381C143.439 44.4227 147.25 44.9244 150.456 46.7758L199.281 74.9647C202.488 76.8161 204.828 79.8656 205.786 83.4423L219.995 136.47L201.937 121.686L200.115 120.194L198.938 122.233Z"
            strokeWidth="4"
        />
        <path
            fill={colorMode == "light" ? "#fcfcfc" : "#150303"}
            stroke={colorMode == "light" ? "#8f0808" : "#ff6565"}
            d="M186.146 156.295L185.146 158.027L183.414 157.027L155.752 141.057L139.782 168.719L138.782 170.451L137.05 169.451L109.653 153.633L99.5854 178.715L106.913 206.062C108.909 213.51 116.564 217.93 124.013 215.935L219.946 190.23C227.394 188.234 231.814 180.578 229.818 173.13L222.485 145.761L201.94 128.941L186.146 156.295Z"
            strokeWidth="4"
        />
        <circle fill={colorMode == "light" ? "#ebebeb" : "#332e2e"} cx="191.07" cy="149.679" r="42.5" />
        <mask id="mask0_46_187" maskUnits="userSpaceOnUse" x="149" y="107" width="86" height="86">
            <circle fill="#D9D9D9" cx="192.07" cy="149.679" r="42.5" transform="rotate(-15 192.07 149.679)" />
        </mask>
        <g mask="url(#mask0_46_187)">
            <path
                fill={colorMode == "light" ? "#fcfcfc" : "#150303"}
                stroke={colorMode == "light" ? "#8f0808" : "#ff6565"}
                d="M203.945 121.235L189.233 146.718L163.75 132.005L162.018 131.005L161.018 132.737L146.305 158.22L120.822 143.508L118.825 142.355L117.966 144.495L109.854 164.704L87.2099 80.1932C85.3834 73.3762 89.4288 66.3692 96.2458 64.5426L149.415 50.2958C152.689 49.4186 156.177 49.8778 159.112 51.5724L204.318 77.6719C207.253 79.3664 209.394 82.1575 210.272 85.4311L223.308 134.084L206.944 120.687L205.123 119.196L203.945 121.235Z"
                strokeWidth="4"
            />
            <path
                fill={colorMode == "light" ? "#fcfcfc" : "#150303"}
                stroke={colorMode == "light" ? "#8f0808" : "#ff6565"}
                d="M196.193 167.227L195.193 168.959L193.461 167.959L167.978 153.246L153.266 178.73L152.266 180.462L150.534 179.462L125.316 164.902L116.076 187.921L122.848 213.193C124.675 220.01 131.682 224.055 138.499 222.229L227.321 198.429C234.138 196.602 238.183 189.595 236.357 182.778L229.579 157.484L210.729 142.051L196.193 167.227Z"
                strokeWidth="4"
            />
        </g>
        <path
            fill={colorMode == "light" ? "#fcfcfc" : "#150303"}
            stroke={colorMode == "light" ? "#8f0808" : "#ff6565"}
            d="M225.564 184.005L226.145 184.566H226.952H231.678L260.919 213.945L260.922 213.948C263.026 216.051 263.026 219.498 260.922 221.601C258.819 223.705 255.372 223.705 253.269 221.601L223.957 192.289V187.56V186.753L223.397 186.172L221.497 184.202L220.188 182.845L218.756 184.072C209.333 192.149 196.488 196.318 182.822 194.029C164.138 190.869 149.197 175.251 146.912 156.437L146.912 156.434C143.412 127.968 167.359 104.021 195.825 107.52L195.828 107.52C214.643 109.805 230.261 124.746 233.421 143.431C235.71 157.097 231.54 169.942 223.464 179.365L222.236 180.797L223.594 182.106L225.564 184.005ZM156.644 150.909C156.644 169.53 171.68 184.566 190.301 184.566C208.922 184.566 223.957 169.53 223.957 150.909C223.957 132.288 208.922 117.253 190.301 117.253C171.68 117.253 156.644 132.288 156.644 150.909Z"
            strokeWidth="4"
        />
        <circle
            fill={colorMode == "light" ? "#fcfcfc" : "#150303"}
            stroke={colorMode == "light" ? "#c61a1b" : "##f32020"}
            cx="52"
            cy="53"
            r="7"
            strokeWidth="2"
        />
        <circle
            fill={colorMode == "light" ? "#fcfcfc" : "#150303"}
            stroke={colorMode == "light" ? "#c61a1b" : "##f32020"}
            cx="282"
            cy="202"
            r="7"
            strokeWidth="2"
        />
        <circle fill={"none"} stroke={colorMode == "light" ? "#c61a1b" : "#f32020"} cx="232" cy="50" r="7" strokeWidth="2" />
        <path
            fill={colorMode == "light" ? "#4f0e13" : "#fae5e5"}
            d="M320 166H315V171C315 171.55 314.55 172 314 172C313.45 172 313 171.55 313 171V166H308C307.45 166 307 165.55 307 165C307 164.45 307.45 164 308 164H313V159C313 158.45 313.45 158 314 158C314.55 158 315 158.45 315 159V164H320C320.55 164 321 164.45 321 165C321 165.55 320.55 166 320 166Z"
        />
        <path
            fill={colorMode == "light" ? "#4f0e13" : "#fae5e5"}
            d="M129 13H124V18C124 18.55 123.55 19 123 19C122.45 19 122 18.55 122 18V13H117C116.45 13 116 12.55 116 12C116 11.45 116.45 11 117 11H122V6C122 5.45 122.45 5 123 5C123.55 5 124 5.45 124 6V11H129C129.55 11 130 11.45 130 12C130 12.55 129.55 13 129 13Z"
        />
        <path
            fill={colorMode == "light" ? "#4f0e13" : "#fae5e5"}
            d="M66 191H61V196C61 196.55 60.55 197 60 197C59.45 197 59 196.55 59 196V191H54C53.45 191 53 190.55 53 190C53 189.45 53.45 189 54 189H59V184C59 183.45 59.45 183 60 183C60.55 183 61 183.45 61 184V189H66C66.55 189 67 189.45 67 190C67 190.55 66.55 191 66 191Z"
        />
    </svg>
);

const NotFoundPage = () => {
    const navigate = useNavigate();
    const colorMode = useColorMode();

    return (
        <ErrorWrapper>
            {icon(colorMode)}
            <Flex direction={"column"} gap={"4rem"} align={"center"}>
                <Flex direction={"column"} gap={"2rem"} align={"center"}>
                    <Title order={1}>Page Not Found</Title>
                    <Text size={"md"}>The page you are looking for could not be found.</Text>
                </Flex>
                <Button fullWidth rightSection={<IconArrowRight />} onClick={() => navigate(-1)}>
                    Go Back
                </Button>
            </Flex>
        </ErrorWrapper>
    );
};

export default NotFoundPage;
