import { AppShell, Flex } from "@mantine/core";
import { ColorModeProvider } from "app/ColorModeProvider";
import { ReactNode } from "react";

const ErrorWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <ColorModeProvider>
            <AppShell bg={"var(--page-bg)"} py={"25%"} w={"100vw"} h={"100vh"}>
                <AppShell.Main>
                    <Flex direction={"column"} align={"center"} gap={"4rem"}>
                        {children}
                    </Flex>
                </AppShell.Main>
            </AppShell>
        </ColorModeProvider>
    );
};

export default ErrorWrapper;
