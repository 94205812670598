import { z } from "zod";

export const ApiKeyPermissionTypesSchema = z.enum([
    "httpDispatch",
    "httpOnDuty",
    "httpAvl",
    "httpTrigger",
    "httpFreshdesk",
    "httpDrone",
    "httpTwilio",
    "httpCloud",
    "httpLotus",
    "httpWebhook",
    "httpScuConfig", // This one, while poorly named, is the scu zone config
    "httpFullScuConfig", // This one is actually for scu config not zone specific
    "httpMoveUnit",
    "wsScuEvents",
    "wsAutomation",
    "wsDrone",
    "httpSvl",
    "httpServiceVersion",
]);
export type ApiKeyPermissionTypes = z.infer<typeof ApiKeyPermissionTypesSchema>;
export const ApiKeyPermissionTypeDescriptions: { [key in ApiKeyPermissionTypes]: string } = {
    httpDispatch: "Dispatch Jobs",
    httpOnDuty: "On/Off Duty",
    httpAvl: "AVL Reporting",
    httpTrigger: "Automation Triggers",
    httpFreshdesk: "Freshdesk Submissions",
    httpDrone: "Drone Data",
    httpTwilio: "Twilio Data",
    httpCloud: "Bryx Cloud Services",
    httpLotus: "Lotus AVL Service",
    httpWebhook: "Webhooks",
    httpScuConfig: "SCU Zone Configs",
    httpFullScuConfig: "Full SCU Configs",
    httpMoveUnit: "SCU Unit Relocations",
    wsScuEvents: "SCU Events Websockets",
    wsAutomation: "Automation Websockets",
    wsDrone: "Drone Websockets",
    httpSvl: "SVL",
    httpServiceVersion: "Service Versions",
};

export const ApiKeyPermissionSchema = z.object({
    type: ApiKeyPermissionTypesSchema,
});
export type ApiKeyPermission = z.infer<typeof ApiKeyPermissionSchema>;

export const ApiKeyOwnerTypeSchema = z.enum(["agency", "other"]);
export type ApiKeyOwnerType = z.infer<typeof ApiKeyOwnerTypeSchema>;

export const ApiKeyOwnerAgencySchema = z.object({
    type: z.literal("agency"),
    agencyId: z.string(),
});
export type ApiKeyOwnerAgency = z.infer<typeof ApiKeyOwnerAgencySchema>;

export const ApiKeyOwnerOtherSchema = z.object({
    type: z.literal("other"),
    name: z.string(),
});
export type ApiKeyOwnerOther = z.infer<typeof ApiKeyOwnerOtherSchema>;

export const ApiKeyOwnerSchema = z.discriminatedUnion("type", [ApiKeyOwnerAgencySchema, ApiKeyOwnerOtherSchema]);
export type ApiKeyOwner = z.infer<typeof ApiKeyOwnerSchema>;

export const ApiKeySchema = z.object({
    id: z.string(),
    key: z.string(),
    name: z.string(),
    permissions: ApiKeyPermissionSchema.array(),
    owner: ApiKeyOwnerSchema,
});
export type ApiKey = z.infer<typeof ApiKeySchema>;

export const ApiKeyParametersSchema = z.object({
    name: z.string(),
    permissions: ApiKeyPermissionSchema.array().nullable(),
    owner: ApiKeyOwnerSchema,
});
export type ApiKeyParameters = z.infer<typeof ApiKeyParametersSchema>;
