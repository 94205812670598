import {
    Accordion,
    AccordionItem,
    Button,
    Checkbox,
    createTheme,
    CSSVariablesResolver,
    DEFAULT_THEME,
    Input,
    Menu,
    mergeMantineTheme,
    Notification,
    rem,
    TabsTab,
    Textarea,
} from "@mantine/core";
import { match } from "ts-pattern";

const themeOverride = createTheme({
    autoContrast: true,
    black: "#150303",
    colors: {
        blue: ["#e7f6ff", "#d0e8ff", "#9fcffc", "#6ab4fb", "#439dfa", "#2d8ffa", "#2188fb", "#1375e1", "#0068c9", "#0059b2"],
        gray: ["#F1F1F1", "#EBEBEB", "#B0B0B0", "#8F8F8F", "#878787", "#737373", "#6F6767", "#484141", "#332E2E", "#292929", "#FCFCFC"],
        green: ["#E2FEF6", "#87F5D4", "#42E6B5", "#00D696", "#00BC83", "#00A170", "#008059", "#006647", "#004B31", "#002619"],
        orange: ["#FCF4ED", "#FFD4AF", "#FFA768", "#FF7C1F", "#EB721B", "#D86512", "#C25100", "#B14A00", "#552A0C", "#36180D"],
        red: ["#FFF9F9", "#FAE5E5", "#FFADAD", "#FF6565", "#F32020", "#C61A1B", "#8F0808", "#620D0D", "#4F0E13", "#230808"],
    },
    components: {
        Accordion: Accordion.extend({
            defaultProps: {
                p: 16,
                style: {
                    border: "1px solid var(--mantine-color-gray-4)",
                },
            },
        }),
        AccordionItem: AccordionItem.extend({
            defaultProps: {
                style: {
                    border: "none",
                },
            },
        }),
        Button: Button.extend({
            vars: (_, props) => {
                return match(props.size)
                    .with("sm", () => ({
                        root: {
                            "--button-fz": rem(12),
                        },
                    }))
                    .with("md", () => ({
                        root: {
                            "--button-fz": rem(14),
                        },
                    }))
                    .with("lg", () => ({
                        root: {
                            "--button-fz": rem(16),
                        },
                    }))
                    .otherwise(() => ({ root: {} }));
            },
        }),
        Checkbox: Checkbox.extend({
            defaultProps: {
                size: "sm",
            },
        }),
        InputLabel: Input.Label.extend({
            defaultProps: {
                mb: "xs",
            },
        }),
        MenuItem: Menu.Item.extend({
            defaultProps: {
                fz: "md",
                p: "md",
            },
        }),
        Notification: Notification.extend({
            defaultProps: {
                miw: rem(300),
                p: "md",
            },
        }),
        TabsTab: TabsTab.extend({
            defaultProps: {
                p: "lg",
                fz: "lg",
            },
        }),
        Textarea: Textarea.extend({
            defaultProps: {
                minRows: 5,
                autosize: true,
            },
        }),
    },
    fontFamily: "Open Sans",
    fontSizes: {
        xs: rem(12),
        sm: rem(14),
        md: rem(16),
        lg: rem(18),
        xl: rem(20),
    },
    headings: {
        sizes: {
            h1: {
                fontWeight: "400",
                fontSize: rem(31),
                lineHeight: rem(44),
            },
            h2: {
                fontWeight: "400",
                fontSize: rem(25),
                lineHeight: rem(36),
            },
            h3: {
                fontWeight: "600",
                fontSize: rem(20),
                lineHeight: rem(24),
            },
            h4: {
                fontWeight: "700",
                fontSize: rem(16),
                lineHeight: rem(22),
            },
            h5: {
                fontWeight: "600",
                fontSize: rem(16),
                lineHeight: rem(22),
            },
            h6: {
                fontWeight: "700",
                fontSize: rem(14),
                lineHeight: rem(18),
            },
        },
    },
    lineHeights: {
        xs: rem(12),
        sm: rem(16),
        md: rem(18),
        lg: rem(20),
        xl: rem(22),
    },
    primaryColor: "red",
    primaryShade: {
        dark: 3,
        light: 6,
    },
    spacing: {
        xs: rem(4),
        sm: rem(8),
        md: rem(12),
        lg: rem(16),
        xl: rem(20),
        "2xl": rem(24),
        "3xl": rem(32),
        "4xl": rem(40),
        "5xl": rem(48),
    },
    white: "#FCFCFC",
});

// TODO: Make theme type strict
// https://mantine.dev/guides/typescript/#theme-object-declarations
// https://github.com/orgs/mantinedev/discussions/4966#discussioncomment-8218580
export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
    variables: {},
    light: {
        "--mantine-color-error": theme.colors.orange[6],
        "--mantine-color-text": theme.black,
        "--header-bg": theme.colors.red[9],
        "--page-bg": theme.white,
    },
    dark: {
        "--mantine-color-error": theme.colors.orange[3],
        "--mantine-color-text": theme.white,
        "--header-bg": theme.colors.red[8],
        "--page-bg": theme.colors.red[9],
    },
});
