import SearchScus from "pages/scus/SearchScus";
import SpecificScu from "pages/scus/SpecificScu";
import { useParams } from "react-router-dom";
import { match, P } from "ts-pattern";

const StationBoardsPage = () => {
    const { id, tab } = useParams();

    return match(id)
        .with(P.string, (id) => <SpecificScu id={id} tab={tab} />)
        .otherwise(() => <SearchScus />);
};

export default StationBoardsPage;
