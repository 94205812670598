import { ActionIcon, Group, TextInput, TextInputProps } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

export type SearchBarProps = TextInputProps & { submit?: () => unknown };
export const SearchBar = ({ submit, size, loading, ...rest }: SearchBarProps & { loading?: boolean }) => (
    <Group gap={4}>
        <TextInput size={size} onKeyUp={submit ? (e) => e.key == "Enter" && submit() : undefined} {...rest} />
        <ActionIcon
            loading={loading}
            size={`input-${size || "sm"}`}
            variant="default"
            aria-label="Search"
            onClick={submit ? () => submit() : undefined}
        >
            <IconSearch size="1rem" />
        </ActionIcon>
    </Group>
);

export default SearchBar;
