import { ActionIcon, ActionIconProps } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

export const AddButton = (props: ActionIconProps & { onClick?: () => void }) => {
    return (
        <ActionIcon size={"lg"} {...props}>
            <IconPlus />
        </ActionIcon>
    );
};
