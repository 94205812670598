import { useMediaQuery } from "shared/hooks/useMediaQuery";
import { useUserStore } from "shared/stores/userStore";

/** Computes what the current color mode should be from the user store, falling back to a media query if 'auto' is preferred */
export function useColorMode(): "light" | "dark" {
    const userSettingsColorMode = useUserStore((s) => s?.colorMode) ?? "auto";
    const isDeviceColorModeDark = useMediaQuery("(prefers-color-scheme: dark)");
    const autoColorMode = isDeviceColorModeDark ? "dark" : "light";

    return userSettingsColorMode == "auto" ? autoColorMode : userSettingsColorMode;
}
