import { LineChart } from "@mantine/charts";
import { Box, Card, Flex, Grid, Title } from "@mantine/core";
import dayjs from "dayjs";
import { DateCount } from "shared/types/bryx911/Dashboard";

export const GraphWidget = ({ title, dateCount }: { title: string; dateCount: DateCount[] }) => (
    <Grid.Col span={4}>
        <Card padding="lg" radius="sm" withBorder>
            <Flex w={"100%"} m={"auto"} direction={"column"} align={"center"} gap={16}>
                <Title order={1}>{title}</Title>
                <Box px={12} pt={2} style={{ width: "100%", height: "100%", minHeight: "18rem" }}>
                    <LineChart
                        h={275}
                        yAxisProps={{ domain: ["dataMin - 50", "dataMax + 50"] }}
                        data={dateCount.map((n) => {
                            return {
                                date: dayjs(n.date * 1000).format("MM/YYYY"),
                                number: n.count,
                            };
                        })}
                        dataKey={"date"}
                        series={[{ name: "number", color: "red.1" }]}
                        curveType={"linear"}
                    />
                </Box>
            </Flex>
        </Card>
    </Grid.Col>
);
